import {
	SET_MUTE_TOGGLE,
	SET_VIDEO_TOGGLE,
	HIDE_HEADER,
	SHOW_HEADER,
	HIDE_FOOOTER,
	SHOW_FOOOTER,
} from '../constants/common';

const initialState = {
	muted: false,
	video: false,
	isHeaderShow: true,
	isFooterShow: true,
};
const commonReducer = (state = initialState, action: any) => {
	const { type, payload } = action;
	switch (type) {
		case SET_MUTE_TOGGLE:
			return { ...state, muted: payload };
		case SET_VIDEO_TOGGLE:
			return { ...state, video: payload };
		case HIDE_HEADER:
			return { ...state, isHeaderShow: payload };
		case SHOW_HEADER:
			return { ...state, isHeaderShow: payload };
		case HIDE_FOOOTER:
			return { ...state, isFooterShow: payload };
		case SHOW_FOOOTER:
			return { ...state, isFooterShow: payload };
		default:
			return state;
	}
};

export default commonReducer;
