import {
	REGISTER_REQ,
	REGISTER_SUCCESS,
	REGISTER_FAIL,
	USER_LOAD_REQ,
	USER_LOADED,
	AUTH_ERROR,
	LOGIN_REQ,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT_SUCCESS,
	CLEAR_PROFILE,
	UPDATE_USER_REQ,
	UPDATE_USER_SUCCESS,
	UPDATE_USER_FAIL,
	UPLOAD_PIC_REQ,
	UPLOAD_PIC_SUCCESS,
	UPLOAD_PIC_FAIL,
	FORGOT_PASSWORD_REQ,
	FORGOT_PASSWORD_SUCCESS,
	FORGOT_PASSWORD_FAIL,
	RESET_PASSWORD_REQ,
	RESET_PASSWORD_SUCCESS,
	RESET_PASSWORD_FAIL,
	VERIFY_EMAIL_SUCCESS,
	VERIFY_EMAIL_FAIL,
	ADMIN_DASHBOARD_ENABLE,
	ADMIN_DASHBOARD_DISABLE,
	TEACHER_DASHBOARD_ENABLE,
	TEACHER_DASHBOARD_DISABLE,
	STUDENT_DASHBOARD_ENABLE,
	STUDENT_DASHBOARD_DISABLE,
	LANGUAGE_CHANGE,
	CONNECT_TO_CHAT,
	DISCONNECT_TO_CHAT,
	SUBSCRIBED_USER_EXISTS,
	SUBSCRIBED_USER_NOT_EXISTS,
	INIT_CONNECT_TO_CHAT,
	IP_ADDRESS_FAIL,
	IP_ADDRESS_SUCCESS,
} from '../constants/auth';

const initialState = {
	token: localStorage.getItem('token'),
	refreshToken: localStorage.getItem('refreshToken'),
	isAuthenticated: false,
	loading: false,
	user: null,
	error: null,
	emailSent: false,
	language: 'en',
	loadingChatFlag: false,
	connectToChatFlag: false,
	ip_address_ec2: null,
	loadingIp: false,
};
const authReducer = (state = initialState, action: any) => {
	const { type, payload, error } = action;
	switch (type) {
		case USER_LOAD_REQ:
			return {
				...state,
				loading: true,
			};

		case USER_LOADED:
			return {
				...state,
				isAuthenticated: true,
				loading: false,
				user: payload,
			};

		case REGISTER_REQ:
		case LOGIN_REQ:
			return {
				...state,
				token: null,
				loading: true,
			};
		case REGISTER_SUCCESS:
		case LOGIN_SUCCESS:
			localStorage.setItem('token', payload.token);
			localStorage.setItem('refreshToken', payload.refreshToken);
			return {
				...state,
				...payload,
				isAuthenticated: true,
				loading: false,
				user: payload,
			};
		case REGISTER_FAIL:
			return {
				...state,
				...payload,
				isAuthenticated: false,
				loading: false,
				error: error,
			};
		case LOGIN_FAIL:
			return {
				...state,
				...payload,
				isAuthenticated: false,
				loading: false,
				error: error,
			};
		case AUTH_ERROR:
		case LOGOUT_SUCCESS:
		case CLEAR_PROFILE:
			localStorage.removeItem('token');
			localStorage.removeItem('onAdminPanal');
			localStorage.removeItem('refreshToken');
			localStorage.removeItem('showCommunity');
			localStorage.removeItem('showFDP');
			localStorage.removeItem('showDPC');
			return {
				...state,
				token: null,
				isAuthenticated: false,
				loading: false,
				user: null,
			};
		case UPDATE_USER_REQ:
			return {
				...state,
				loading: true,
			};
		case UPDATE_USER_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case UPDATE_USER_FAIL:
			return {
				...state,
				loading: false,
			};
		case UPLOAD_PIC_REQ:
			return {
				...state,
				loading: true,
			};
		case UPLOAD_PIC_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case UPLOAD_PIC_FAIL:
			return {
				...state,
				loading: false,
			};
		case FORGOT_PASSWORD_REQ:
			return {
				...state,
				loading: true,
			};
		case FORGOT_PASSWORD_SUCCESS:
			return {
				...state,
				loading: false,
				emailSent: true,
			};
		case FORGOT_PASSWORD_FAIL:
			return {
				...state,
				loading: false,
				emailSent: false,
			};
		case RESET_PASSWORD_REQ:
			return {
				...state,
				loading: true,
			};
		case RESET_PASSWORD_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case RESET_PASSWORD_FAIL:
			return {
				...state,
				loading: false,
			};
		case VERIFY_EMAIL_SUCCESS:
			return {
				...state,
				loading: false,
				verified: true,
			};
		case VERIFY_EMAIL_FAIL:
			return {
				...state,
				loading: false,
				verified: false,
			};
		case ADMIN_DASHBOARD_ENABLE:
			return {
				...state,
				loading: false,
				adminEnable: true,
			};
		case ADMIN_DASHBOARD_DISABLE:
			return {
				...state,
				loading: false,
				adminEnable: false,
			};
		case STUDENT_DASHBOARD_ENABLE:
			return {
				...state,
				loading: false,
				studentEnable: true,
			};
		case STUDENT_DASHBOARD_DISABLE:
			return {
				...state,
				loading: false,
				studentEnable: false,
			};
		case TEACHER_DASHBOARD_ENABLE:
			return {
				...state,
				loading: false,
				teacherEnable: true,
			};
		case TEACHER_DASHBOARD_DISABLE:
			return {
				...state,
				loading: false,
				teacherEnable: false,
			};
		case LANGUAGE_CHANGE:
			return {
				...state,
				loading: false,
				language: payload.language,
			};

		case INIT_CONNECT_TO_CHAT:
			return {
				...state,
				loadingChatFlag: true,
				connectToChatFlag: false,
			};
		case CONNECT_TO_CHAT:
			return {
				...state,
				loadingChatFlag: false,
				connectToChatFlag: true,
			};
		case DISCONNECT_TO_CHAT:
			return {
				...state,
				loadingChatFlag: false,
				connectToChatFlag: false,
			};
		case SUBSCRIBED_USER_EXISTS:
			return {
				...state,
				loading: false,
				userSubscribedFlag: true,
			};
		case SUBSCRIBED_USER_NOT_EXISTS:
			return {
				...state,
				loading: false,
				userSubscribedFlag: false,
			};
		case IP_ADDRESS_SUCCESS:
			return {
				...state,
				loadingIp: false,
				ip_address_ec2: payload,
			};

		case IP_ADDRESS_FAIL:
			return {
				...state,
				loadingIp: false,
				ip_address_ec2: false,
			};
		default:
			return state;
	}
};

export default authReducer;
