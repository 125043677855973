import React, { useLayoutEffect, useState, useEffect } from 'react';
import './Sidebar.css';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import {
	MdOtherHouses,
	MdOutlineSocialDistance,
	MdHouseSiding,
	MdDashboard,
	MdPostAdd,
	MdGroup,
	MdGroups,
	MdEmail,
	MdInbox,
} from 'react-icons/md';

import { FaUsers, FaUsersCog, FaUserPlus } from 'react-icons/fa';
import { BiBookContent } from 'react-icons/bi';
import { RiPagesLine } from 'react-icons/ri';

import Multilingual from 'component/common/multilingual/Multilingual';
//3
const Sidebar: React.FC = () => {
	const { t } = useTranslation(['sidebar', 'dashboard']);

	const links = [
		{
			id: 1,
			name: t('dashboard'),
			to: '/dashboard',
			className: 'nav-item',
			type: 'individual',
			icon: (
				<svg width="24" height="22" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M22.6801 20.7775C22.9727 20.4984 23.1357 20.1214 23.1357 19.7299V11.8108C23.1351 11.6069 23.0905 11.405 23.0045 11.2178C22.9184 11.0306 22.7927 10.8617 22.6346 10.722L22.6801 20.7775ZM22.6801 20.7775C22.3872 21.0568 21.9885 21.2149 21.5714 21.2149H16.5786M22.6801 20.7775L16.5786 21.2149M16.5786 21.2149V14.0071C16.5786 13.7482 16.4707 13.5013 16.281 13.3205C16.0917 13.1399 15.8363 13.0396 15.5714 13.0396H10.4286C10.1637 13.0396 9.90831 13.1399 9.71895 13.3205C9.52935 13.5013 9.42143 13.7482 9.42143 14.0071V21.2149H4.42857C4.01147 21.2149 3.61279 21.0568 3.31992 20.7775C3.02728 20.4984 2.86429 20.1214 2.86429 19.7299V11.8106C2.86482 11.6068 2.90937 11.4052 2.99541 11.2181C3.08146 11.031 3.20728 10.8623 3.36541 10.7228L16.5786 21.2149ZM23.9961 9.29523L23.996 9.29508L15.4246 1.74841L15.424 1.74794C14.7608 1.16971 13.8961 0.85 13 0.85C12.1039 0.85 11.2392 1.16971 10.576 1.74793L10.5754 1.74841L2.00402 9.29508L2.00386 9.29523C1.64124 9.61543 1.35131 10.004 1.15261 10.4367C0.953896 10.8696 0.850798 11.3371 0.85 11.8101V11.8104V19.7299C0.85 20.6393 1.22884 21.5099 1.90068 22.1507C2.57227 22.7913 3.4817 23.15 4.42857 23.15H10.4286C10.6935 23.15 10.9488 23.0497 11.1382 22.8691C11.3278 22.6883 11.4357 22.4414 11.4357 22.1825V14.9746H14.5643V22.1825C14.5643 22.4414 14.6722 22.6883 14.8618 22.8691C15.0512 23.0497 15.3065 23.15 15.5714 23.15H21.5714C22.5183 23.15 23.4277 22.7913 24.0993 22.1507C24.7712 21.5099 25.15 20.6393 25.15 19.7299V11.8104V11.8101C25.1492 11.3371 25.0461 10.8696 24.8474 10.4367C24.6487 10.004 24.3588 9.61543 23.9961 9.29523ZM3.36551 10.7227L11.9364 3.17657C11.9365 3.17651 11.9365 3.17645 11.9366 3.17639C12.2259 2.92379 12.6053 2.78274 13 2.78274C13.3947 2.78274 13.774 2.92378 14.0634 3.17637C14.0635 3.17644 14.0635 3.1765 14.0636 3.17657L22.6344 10.7218L3.36551 10.7227Z"
						fill="white"
						stroke="#31D1B5"
						strokeWidth="0.3"
					/>
				</svg>
			),
		},
		{
			id: 3,
			name: t('schedule'),
			to: '/schedule',
			className: 'nav-item',
			type: 'individual',
			icon: (
				<svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" fill="currentColor" viewBox="0 0 16 16">
					<path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
					<path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
				</svg>
			),
		},

		{
			id: 4,
			name: t('generation'),
			to: '/generation',
			type: 'individual',
			className: 'nav-item',
			icon: (
				<svg width="24" height="22" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M14.5 0C6.49186 0 0 6.49186 0 14.5C0 22.5081 6.49186 29 14.5 29C22.5081 29 29 22.5081 29 14.5C29 6.49186 22.5081 0 14.5 0ZM14.5 1.97739C7.58402 1.97739 1.97718 7.58422 1.97718 14.5002C1.97718 21.4162 7.58402 27.023 14.5 27.023C21.416 27.023 27.0228 21.4162 27.0228 14.5002C27.0228 7.58422 21.416 1.97739 14.5 1.97739Z"
						fill="white"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M14.4999 11.8633C15.0459 11.8633 15.4885 12.4093 15.4885 12.8519V18.7839C15.4885 19.3299 15.0459 19.7725 14.4999 19.7725C13.954 19.7725 13.5114 19.2265 13.5114 18.7839V12.8519C13.5114 12.3059 13.954 11.8633 14.4999 11.8633Z"
						fill="white"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M15.818 9.22749C15.818 9.95534 15.2279 10.5456 14.4999 10.5456C13.7719 10.5456 13.1818 9.95534 13.1818 9.22749C13.1818 8.49943 13.7719 7.90918 14.4999 7.90918C15.2279 7.90918 15.818 8.49943 15.818 9.22749Z"
						fill="white"
					/>
				</svg>
			),
		},

		{
			id: 5,
			name: t('schedule'),
			to: '/dyad-schedule',
			className: 'nav-item',
			type: 'individual',
			icon: (
				<svg width="24" height="22" viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M3.6666 0.000569395C1.67078 0.000569395 0 1.67157 0 3.66717V24.4449C0 26.1218 1.3786 27.5004 3.05555 27.5004H21.0833C21.5633 27.5004 22 27.0637 22 26.5837V6.41711C21.997 5.87894 21.6076 5.4957 21.0833 5.50039C20.2013 5.50039 19.2498 4.54913 19.2498 3.66695C19.2498 2.78499 20.2013 1.83352 21.0833 1.83352C21.5675 1.84034 22 1.40127 22 0.916797C22 0.432548 21.5675 -0.00674203 21.0833 7.83864e-05L3.6666 0.000569395ZM3.6666 1.83401H17.9224C17.6024 2.37623 17.4164 3.00374 17.4164 3.66745C17.4164 4.33115 17.6025 4.95841 17.9224 5.50088H3.6666C2.78464 5.50088 1.83317 4.54963 1.83317 3.66745C1.83317 2.78548 2.78464 1.83401 3.6666 1.83401ZM1.83317 6.82781C2.37496 7.14709 3.00371 7.3338 3.6666 7.3338H20.1666V25.6671H3.05549C2.36259 25.6671 1.83338 25.1379 1.83338 24.445L1.83317 6.82781ZM5.19438 10.695C4.68818 10.695 4.27766 11.1055 4.27766 11.6117C4.27766 12.1179 4.68816 12.5284 5.19438 12.5284H16.8055C17.3117 12.5284 17.7222 12.1181 17.7222 11.6117C17.7222 11.1055 17.3119 10.695 16.8055 10.695H5.19438ZM5.19438 15.584C4.68818 15.584 4.27766 15.9945 4.27766 16.5007C4.27766 17.0069 4.68816 17.4174 5.19438 17.4174H16.8055C17.3117 17.4174 17.7222 17.0071 17.7222 16.5007C17.7222 15.9945 17.3119 15.584 16.8055 15.584H5.19438Z"
						fill="white"
					/>
				</svg>
			),
		},
	];

	const adminLinks = [
		{
			id: 1,
			name: t('dashboard:WELCOME'),
			to: '/welcome',
			className: 'nav-item',
			type: 'individual',
			icon: <MdDashboard size={24} />,
		},
		{
			id: 2,
			name: t('manageCourse'),
			className: 'nav-item',
			type: 'group',
			submenu: [
				{
					id: 2.1,
					name: t('allCourse'),
					to: '/admin-dashboard',
					className: 'nav-subitem',
					type: 'indivual',
					icon: <MdOtherHouses size={16} />,
				},
				{
					id: 2.2,
					name: t('subscription'),
					to: '/course-subscription',
					className: 'nav-subitem',
					type: 'indivual',
					icon: <MdHouseSiding size={16} />,
				},
			],
			icon: <MdOtherHouses size={24} />,
		},
		{
			id: 4,
			name: t('createPairs'),
			className: 'nav-item',
			type: 'group',
			submenu: [
				{
					id: 4.1,
					name: t('course'),
					to: '/create-pairs',
					className: 'nav-subitem',
					type: 'indivual',
					icon: <MdGroups size={18} />,
				},
				{
					id: 4.2,
					name: t('Validate Pairing'),
					to: '/validate-pairs',
					className: 'nav-subitem',
					type: 'indivual',
					icon: <MdGroups size={18} />,
				},
				{
					id: 4.3,
					name: t('subscriptionPair'),
					to: '/subscription-list',
					className: 'nav-subitem',
					type: 'indivual',
					icon: <MdGroup size={18} />,
				},
				{
					id: 4.4,
					name: t('Community Enrollment'),
					to: '/community-enrollment',
					className: 'nav-subitem',
					type: 'indivual',
					icon: <MdGroup size={18} />,
				},
			],
			icon: <MdOutlineSocialDistance size={24} />,
		},
		{
			id: 10,
			name: t('Manage Subscription'),
			className: 'nav-item',
			type: 'group',
			submenu: [
				{
					id: 5.1,
					name: t('All Subscription'),
					to: '/all/subscription',
					className: 'nav-subitem',
					type: 'indivual',
					icon: <FaUsers size={16} />,
				},
			],
			icon: <FaUsersCog size={24} />,
		},
		{
			id: 5,
			name: t('manageUsers'),
			className: 'nav-item',
			type: 'group',
			submenu: [
				{
					id: 5.1,
					name: t('allUsers'),
					to: '/users',
					className: 'nav-subitem',
					type: 'indivual',
					icon: <FaUsers size={16} />,
				},
				{
					id: 5.2,
					name: t('registerUser'),
					to: '/user-registration',
					className: 'nav-subitem',
					type: 'indivual',
					icon: <FaUserPlus size={16} />,
				},
				{
					id: 5.3,
					name: t('importUsers'),
					to: '/import-users',
					className: 'nav-subitem',
					type: 'indivual',
					icon: (
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" fill="currentColor" viewBox="0 0 16 16">
							<path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z" />
						</svg>
					),
				},
			],
			icon: <FaUsersCog size={24} />,
		},
		{
			id: 6,
			name: t('dyadHistory'),
			to: '/dyadHistory',
			className: 'nav-item',
			type: 'individual',
			icon: <MdHouseSiding size={24} />,
		},
		{
			id: 7,
			name: t('messaging'),
			to: '/message',
			className: 'nav-item',
			type: 'group',
			submenu: [
				{
					id: 7.1,
					name: t('Compose'),
					to: '/send-email',
					className: 'nav-subitem',
					type: 'indivual',
					icon: <MdEmail size={18} />,
				},
				{
					id: 7.2,
					name: t('Inbox'),
					to: '/admin-inbox',
					className: 'nav-subitem',
					type: 'indivual',
					icon: <MdInbox size={18} />,
				},
			],
			icon: <BiBookContent size={24} />,
		},
		{
			id: 8,
			name: t('CMS'),
			to: '/cms',
			className: 'nav-item',
			type: 'group',
			submenu: [
				{
					id: 8.3,
					name: t('Course Content'),
					to: '/admin/course-content',
					className: 'nav-subitem',
					type: 'indivual',
					icon: <MdPostAdd size={20} />,
				},
				{
					id: 8.1,
					name: t('All Pages'),
					to: '/all-pages',
					className: 'nav-subitem',
					type: 'indivual',
					icon: <RiPagesLine size={20} />,
				},
				{
					id: 8.2,
					name: t('Add Page'),
					to: '/add-page',
					className: 'nav-subitem',
					type: 'indivual',
					icon: <MdPostAdd size={20} />,
				},
			],
			icon: <BiBookContent size={24} />,
		},
	];

	const teacherLinks = [
		{
			id: 1,
			name: t('dashboard:WELCOME'),
			to: '/welcome',
			className: 'nav-item',
			type: 'individual',
			icon: <MdDashboard size={24} />,
		},
		// {
		// 	id: 4,
		// 	name: t('editPairs'),
		// 	to: '/create-pairs',
		// 	type: 'individual',
		// 	className: 'nav-item',
		// 	icon: <MdOutlineSocialDistance size={24} />,
		// },
		{
			id: 7,
			name: t('messaging'),
			to: '/message',
			className: 'nav-item',
			type: 'group',
			submenu: [
				{
					id: 7.1,
					name: t('Compose'),
					to: '/send-email',
					className: 'nav-subitem',
					type: 'indivual',
					icon: <MdEmail size={18} />,
				},
				{
					id: 7.2,
					name: t('Inbox'),
					to: '/teacher-inbox',
					className: 'nav-subitem',
					type: 'indivual',
					icon: <MdInbox size={18} />,
				},
			],
			icon: <BiBookContent size={24} />,
		},
	];

	const location = useLocation();
	const [isUserAdmin, setUserAdmin] = useState<boolean | null>(false);
	const [isUserTeacher, setUserTeacher] = useState<boolean | null>(false);
	const [activeLink, setActiveLink] = useState<number | null>(1);
	const [collapsedMenu, setCollapsedMenu] = useState<number | null>(null);
	const [hideShowSidebar, setHideShowSidebar] = useState<boolean | null>(true);

	useLayoutEffect(() => {
		links.forEach((link: any) => {
			if (link.type === 'individual') {
				if (location.pathname.indexOf(link.to) !== -1) {
					setActiveLink(link.id);
					setCollapsedMenu(null);
				}
			} else {
				link?.submenu.forEach((submenu: any) => {
					if (location.pathname.indexOf(submenu.to) !== -1) {
						setActiveLink(submenu.id);
						setCollapsedMenu(link.id);
					}
				});
			}
		});

		if (isUserAdmin) {
			adminLinks.forEach((link: any) => {
				if (link.type === 'individual') {
					if (location.pathname.indexOf(link.to) !== -1) {
						setActiveLink(link.id);
						setCollapsedMenu(null);
					}
				} else {
					link?.submenu.forEach((submenu: any) => {
						if (location.pathname.indexOf(submenu.to) !== -1) {
							setActiveLink(submenu.id);
							setCollapsedMenu(link.id);
						}
					});
				}
			});
		}

		if (isUserTeacher) {
			teacherLinks.forEach((link: any) => {
				if (link.type === 'individual') {
					if (location.pathname.indexOf(link.to) !== -1) {
						setActiveLink(link.id);
						setCollapsedMenu(null);
					}
				} else {
					link?.submenu.forEach((submenu: any) => {
						if (location.pathname.indexOf(submenu.to) !== -1) {
							setActiveLink(submenu.id);
							setCollapsedMenu(link.id);
						}
					});
				}
			});
		}
		// eslint-disable-next-line 
	}, [location.pathname, isUserAdmin, isUserTeacher]);

	const sidebar = useSelector((state: RootState) => state.sidebar);
	const { adminEnable } = useSelector((state: RootState) => state.auth);
	const { teacherEnable } = useSelector((state: RootState) => state.auth);
	const handleSetCollapsibleMenu = (linkId: any) => {
		if (collapsedMenu === linkId) {
			setCollapsedMenu(null);
		} else {
			setCollapsedMenu(linkId);
		}
	};

	useEffect(() => {
		const isHideShowSidebar = sidebar && sidebar.isSidebarShow;
		setHideShowSidebar(isHideShowSidebar);
	}, [sidebar]);

	useEffect(() => {
		setUserAdmin(adminEnable);
		setUserTeacher(teacherEnable);
	}, [adminEnable, teacherEnable]);

	return (
		<>
			{isUserAdmin
				? hideShowSidebar && (
						<nav
							data-testid="sidebar"
							id="sidebar"
							className={'sidebar sidebar-offcanvas'}
							style={{
								background: '#2f3247',
							}}>
							<ul className="nav">
								<div style={{ marginLeft: '7px', marginBottom: '5px' }}>
									<Multilingual />
								</div>

								{adminLinks.map((link: any) => (
									<li key={link.id} className={link.className + (link.id === activeLink ? ' active' : '')}>
										{link.type === 'individual' && (
											<NavLink
												className="nav-link admin"
												to={link.to}
												onClick={() => {
													const nav = document.querySelector('nav');
													nav?.classList.toggle('active');
												}}>
												{link.icon}
												<span className="menu-title">{link.name}</span>
											</NavLink>
										)}
										{link.type === 'group' && (
											<>
												<button
													className="dropdown-btn nav-link admin"
													onClick={() => handleSetCollapsibleMenu(link.id)}>
													{link.icon}
													<span className="menu-title">{link.name}</span>
													<i className="fa fa-caret-down"></i>
												</button>
												<div
													className="dropdown-container"
													style={{ display: collapsedMenu === link.id ? 'block' : 'none' }}>
													<ul style={{ listStyleType: 'none', paddingLeft: '1.5rem' }}>
														{link.submenu.map((submenu: any) => (
															<li
																key={submenu.id}
																className={submenu.className + (submenu.id === activeLink ? ' active' : '')}>
																<NavLink
																	className="nav-link admin"
																	to={submenu.to}
																	onClick={() => {
																		const nav = document.querySelector('nav');
																		nav?.classList.toggle('active');
																	}}>
																	{submenu.icon}
																	<span className="menu-title">{submenu.name}</span>
																</NavLink>
															</li>
														))}
													</ul>
												</div>
											</>
										)}
									</li>
								))}
							</ul>
						</nav>
					)
				: isUserTeacher
					? hideShowSidebar && (
							<nav
								data-testid="sidebar"
								id="sidebar"
								className={'sidebar sidebar-offcanvas'}
								style={{
									background: '#2f3247',
								}}>
								<ul className="nav">
									{teacherLinks.map((link) => (
										<li key={link.id} className={link.className + (link.id === activeLink ? ' active' : '')}>
											{link.type === 'individual' && (
												<NavLink
													className="nav-link admin"
													to={link.to}
													onClick={() => {
														const nav = document.querySelector('nav');
														nav?.classList.toggle('active');
													}}>
													{link.icon}
													<span className="menu-title">{link.name}</span>
												</NavLink>
											)}
											{link.type === 'group' && (
												<>
													<button
														className="dropdown-btn nav-link admin"
														onClick={() => handleSetCollapsibleMenu(link.id)}>
														{link.icon}
														<span className="menu-title">{link.name}</span>
														<i className="fa fa-caret-down"></i>
													</button>
													<div
														className="dropdown-container"
														style={{ display: collapsedMenu === link.id ? 'block' : 'none' }}>
														<ul style={{ listStyleType: 'none', paddingLeft: '1.5rem' }}>
															{link?.submenu?.map((submenu: any) => (
																<li
																	key={submenu.id}
																	className={submenu.className + (submenu.id === activeLink ? ' active' : '')}>
																	<NavLink
																		className="nav-link admin"
																		to={submenu.to}
																		onClick={() => {
																			const nav = document.querySelector('nav');
																			nav?.classList.toggle('active');
																		}}>
																		{submenu.icon}
																		<span className="menu-title">{submenu.name}</span>
																	</NavLink>
																</li>
															))}
														</ul>
													</div>
												</>
											)}
										</li>
									))}
								</ul>
							</nav>
						)
					: hideShowSidebar && (
							<nav
								data-testid="sidebar"
								id="sidebar"
								className={'sidebar sidebar-offcanvas'}
								style={{
									background: 'linear-gradient(180deg, #157175 0%, #2b9495 35.65%, #2b9696 59.9%, #18777a 100%)',
								}}>
								<ul className="nav">
									{links.map((link) => (
										<li key={link.id} className={link.className + (link.id === activeLink ? ' active' : '')}>
											<NavLink
												className="nav-link non-admin"
												to={link.to}
												onClick={() => {
													const nav = document.querySelector('nav');
													nav?.classList.toggle('active');
												}}>
												{link.icon}
												<span className="menu-title">{link.name}</span>
											</NavLink>
										</li>
									))}
								</ul>
							</nav>
						)}
		</>
	);
};

export default Sidebar;
