export const JOIN_ROOM_INIT = "JOIN_ROOM_INIT";
export const JOIN_ROOM_SUCCESS = "JOIN_ROOM_SUCCESS";
export const JOIN_ROOM_FAIL = "JOIN_ROOM_FAIL";
export const LEAVE_ROOM_INIT = "LEAVE_ROOM_INIT";
export const LEAVE_ROOM_SUCCESS = "LEAVE_ROOM_SUCCESS";
export const LEAVE_ROOM_FAIL = "LEAVE_ROOM_FAIL";

export const REMOVE_DYAD_REQ = "REMOVE_DYAD_REQ";
export const REMOVE_DYAD_SUCCESS = "REMOVE_DYAD_SUCCESS";
export const REMOVE_DYAD_FAIL = "REMOVE_DYAD_FAIL";
