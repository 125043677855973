import {
	ZOOM_JOIN_INIT,
	ZOOM_JOIN_SUCCESS,
	ZOOM_JOIN_RESET,
	ZOOM_UPDATE_PARTNER_INIT,
	ZOOM_UPDATE_PARTNER_SUCCESS,
	ZOOM_UPDATE_PARTNER_FAIL,
	ZOOM_VIDEO_INIT,
	ZOOM_VIDEO_SUCCESS,
	ZOOM_VIDEO_FAIL,
	ZOOM_AUDIO_INIT,
	ZOOM_AUDIO_SUCCESS,
	ZOOM_AUDIO_FAIL,
	ZOOM_AUDIO_MUTE_INIT,
	ZOOM_AUDIO_MUTE_FAIL,
	ZOOM_PARTNER_CONNECTIONED,
	ZOOM_PARTNER_REMOVED,
	ZOOM_SPEAKER_ACTIVE_ID,
	SET_SPEAKER_LISTNER_PARTNER,
	ZOOM_CAMERA_ON_OFF,
	SCRIPT_AUTO_START,
	SET_LISTNER_PARTNER,
	SET_SPEAKER_PARTNER,
	SET_USER_PERMISSION,
	SET_CURRENT_USER_SPEAKER_LISTNER,
	SET_ACTIVE_COCOONID,
	ZOOM_COCOON_NAME,
	ZOOM_STOP_DYAD,
} from '../constants/zoom';

const initialState = {
	loading_zoom: true,
	zoomSdk: null,
	partners: null,
	video_loading: false,
	isAudioEnable: false,
	isPartnerConnected: false,
	isPartnerLeft: false,
	activeSpeakerId: null,
	isCameraOn: true,
	isScriptStarted: false,
	listerSpeakerPartner: null,
	listnerPartner: null,
	speakerPartner: null,
	audioVideoPermission: null,
	isCurrentUserListnerSpeaker: null,
	activeCocoonId: null,
	// zoomPage: false,
	zCocoonName: null,
	stopDyad: false,
};

const zoomReducers = (state = initialState, action: any) => {
	const { type, payload } = action;
	switch (type) {
		case ZOOM_JOIN_INIT:
			return {
				...state,
				loading_zoom: true,
			};
		case ZOOM_JOIN_SUCCESS:
			return {
				...state,
				loading_zoom: false,
				zoomSdk: payload,
			};

		case ZOOM_JOIN_RESET:
			console.log(`Reseting all data for zoom ...`);
			return {
				...state,
				loading_zoom: true,
				zoomSdk: null,
				partners: null,
				video_loading: false,
				isAudioEnable: false,
				isPartnerConnected: false,
				isPartnerLeft: false,
				activeSpeakerId: null,
				isCameraOn: true,
				isScriptStarted: false,
				listerSpeakerPartner: null,
				listnerPartner: null,
				speakerPartner: null,
				isCurrentUserListnerSpeaker: null,
				audioVideoPermission: null,
				activeCocoonId: null,
				zCocoonName: null,
				stopDyad: false,
			};

		case ZOOM_UPDATE_PARTNER_SUCCESS:
			return {
				...state,
				loading_zoom: false,
				partners: payload,
			};

		case ZOOM_UPDATE_PARTNER_FAIL:
			return {
				...state,
				loading_zoom: false,
				partners: null,
			};

		case ZOOM_VIDEO_INIT:
			return {
				...state,
				video_loading: true,
			};

		case ZOOM_VIDEO_SUCCESS:
			return {
				...state,
				video_loading: false,
			};

		case ZOOM_VIDEO_FAIL:
			return {
				...state,
				video_loading: false,
			};

		case ZOOM_AUDIO_INIT:
			return {
				...state,
			};

		case ZOOM_AUDIO_SUCCESS:
			return {
				...state,
				isAudioEnable: true,
			};

		case ZOOM_AUDIO_FAIL:
			return {
				...state,
			};

		case ZOOM_AUDIO_MUTE_INIT:
			return {
				...state,
			};

		case ZOOM_AUDIO_MUTE_FAIL:
			return {
				...state,
			};

		case ZOOM_PARTNER_CONNECTIONED:
			return {
				...state,
				isPartnerConnected: payload,
			};

		case ZOOM_PARTNER_REMOVED:
			return {
				...state,
				isPartnerLeft: payload,
			};

		case ZOOM_SPEAKER_ACTIVE_ID:
			return {
				...state,
				activeSpeakerId: payload,
			};

		case ZOOM_CAMERA_ON_OFF:
			return {
				...state,
				isCameraOn: payload,
			};

		case SCRIPT_AUTO_START:
			return {
				...state,
				isScriptStarted: true,
			};

		case SET_SPEAKER_LISTNER_PARTNER:
			return {
				...state,
				listerSpeakerPartner: payload,
			};

		case SET_LISTNER_PARTNER:
			return {
				...state,
				listnerPartner: payload,
			};

		case SET_SPEAKER_PARTNER:
			return {
				...state,
				speakerPartner: payload,
			};

		case SET_CURRENT_USER_SPEAKER_LISTNER:
			return {
				...state,
				isCurrentUserListnerSpeaker: payload,
			};

		case SET_USER_PERMISSION:
			return {
				...state,
				audioVideoPermission: payload,
			};
		case SET_ACTIVE_COCOONID:
			return {
				...state,
				activeCocoonId: payload,
			};
		// case SET_ZOOM_PAGE:
		// 	return {
		// 		...state,
		// 		zoomPage: true,
		// 	};
		case ZOOM_COCOON_NAME:
			return {
				...state,
				zCocoonName: payload,
			};
		case ZOOM_STOP_DYAD:
			return {
				...state,
				stopDyad: true,
			};

		default:
			return state;
	}
};

export default zoomReducers;
