type ICustomTimezone = {
	[key: string]: string;
};

const allTimezones: ICustomTimezone = {
	'Pacific/Niue': 'Niue Time',
	'Pacific/Pago_Pago': 'Samoa Standard Time',
	'Pacific/Rarotonga': 'Cook Islands Standard Time',
	'Pacific/Honolulu': 'Hawaii-Aleutian Standard Time',
	'America/Adak': 'Hawaii-Aleutian Time',
	'Pacific/Tahiti': 'Tahiti Time',
	'Pacific/Marquesas': 'Marquesas Time',
	'America/Anchorage': 'Alaska Time - Anchorage',
	'America/Juneau': 'Alaska Time - Juneau',
	'America/Metlakatla': 'Alaska Time - Metlakatla',
	'America/Nome': 'Alaska Time - Nome',
	'America/Sitka': 'Alaska Time - Sitka',
	'America/Yakutat': 'Alaska Time - Yakutat',
	'Pacific/Gambier': 'Gambier Time',
	'America/Los_Angeles': 'Pacific Time - Los Angeles',
	'America/Tijuana': 'Pacific Time - Tijuana',
	'America/Vancouver': 'Pacific Time - Vancouver',
	'Pacific/Pitcairn': 'Pitcairn Time',
	'America/Hermosillo': 'Mexican Pacific Standard Time',
	'America/Mazatlan': 'Mexican Pacific Time',
	'America/Dawson_Creek': 'Mountain Standard Time - Dawson Creek',
	'America/Fort_Nelson': 'Mountain Standard Time - Fort Nelson',
	'America/Phoenix': 'Mountain Standard Time - Phoenix',
	'America/Boise': 'Mountain Time - Boise',
	'America/Cambridge_Bay': 'Mountain Time - Cambridge Bay',
	'America/Denver': 'Mountain Time - Denver',
	'America/Edmonton': 'Mountain Time - Edmonton',
	'America/Inuvik': 'Mountain Time - Inuvik',
	'America/Yellowknife': 'Mountain Time - Yellowknife',
	'America/Dawson': 'Yukon Time - Dawson',
	'America/Whitehorse': 'Yukon Time - Whitehorse',
	'America/Belize': 'Central Standard Time - Belize',
	'America/Costa_Rica': 'Central Standard Time - Costa Rica',
	'America/El_Salvador': 'Central Standard Time - El Salvador',
	'America/Guatemala': 'Central Standard Time - Guatemala',
	'America/Managua': 'Central Standard Time - Managua',
	'America/Regina': 'Central Standard Time - Regina',
	'America/Swift_Current': 'Central Standard Time - Swift Current',
	'America/Tegucigalpa': 'Central Standard Time - Tegucigalpa',
	'America/Bahia_Banderas': 'Central Time - Bahia Banderas',
	'America/North_Dakota/Beulah': 'Central Time - Beulah, North Dakota',
	'America/North_Dakota/Center': 'Central Time - Center, North Dakota',
	'America/Chicago': 'Central Time - Chicago',
	'America/Chihuahua': 'Central Time - Chihuahua',
	'America/Indiana/Knox': 'Central Time - Knox, Indiana',
	'America/Matamoros': 'Central Time - Matamoros',
	'America/Menominee': 'Central Time - Menominee',
	'America/Merida': 'Central Time - Merida',
	'America/Mexico_City': 'Central Time - Mexico City',
	'America/Monterrey': 'Central Time - Monterrey',
	'America/North_Dakota/New_Salem': 'Central Time - New Salem, North Dakota',
	'America/Ojinaga': 'Central Time - Ojinaga',
	'America/Rainy_River': 'Central Time - Rainy River',
	'America/Rankin_Inlet': 'Central Time - Rankin Inlet',
	'America/Resolute': 'Central Time - Resolute',
	'America/Indiana/Tell_City': 'Central Time - Tell City, Indiana',
	'America/Winnipeg': 'Central Time - Winnipeg',
	'Pacific/Galapagos': 'Galapagos Time',
	'America/Eirunepe': 'Acre Standard Time - Eirunepe',
	'America/Rio_Branco': 'Acre Standard Time - Rio Branco',
	'America/Bogota': 'Colombia Standard Time',
	'America/Havana': 'Cuba Time',
	'Pacific/Easter': 'Easter Island Time',
	'America/Cancun': 'Eastern Standard Time - Cancun',
	'America/Jamaica': 'Eastern Standard Time - Jamaica',
	'America/Panama': 'Eastern Standard Time - Panama',
	'America/Detroit': 'Eastern Time - Detroit',
	'America/Grand_Turk': 'Eastern Time - Grand Turk',
	'America/Indiana/Indianapolis': 'Eastern Time - Indianapolis',
	'America/Iqaluit': 'Eastern Time - Iqaluit',
	'America/Kentucky/Louisville': 'Eastern Time - Louisville',
	'America/Indiana/Marengo': 'Eastern Time - Marengo, Indiana',
	'America/Kentucky/Monticello': 'Eastern Time - Monticello, Kentucky',
	'America/New_York': 'Eastern Time - New York',
	'America/Nipigon': 'Eastern Time - Nipigon',
	'America/Pangnirtung': 'Eastern Time - Pangnirtung',
	'America/Indiana/Petersburg': 'Eastern Time - Petersburg, Indiana',
	'America/Port-au-Prince': 'Eastern Time - Port-au-Prince',
	'America/Thunder_Bay': 'Eastern Time - Thunder Bay',
	'America/Toronto': 'Eastern Time - Toronto',
	'America/Indiana/Vevay': 'Eastern Time - Vevay, Indiana',
	'America/Indiana/Vincennes': 'Eastern Time - Vincennes, Indiana',
	'America/Indiana/Winamac': 'Eastern Time - Winamac, Indiana',
	'America/Guayaquil': 'Ecuador Time',
	'America/Lima': 'Peru Standard Time',
	'America/Boa_Vista': 'Amazon Standard Time - Boa Vista',
	'America/Campo_Grande': 'Amazon Standard Time - Campo Grande',
	'America/Cuiaba': 'Amazon Standard Time - Cuiaba',
	'America/Manaus': 'Amazon Standard Time - Manaus',
	'America/Porto_Velho': 'Amazon Standard Time - Porto Velho',
	'America/Barbados': 'Atlantic Standard Time - Barbados',
	'America/Martinique': 'Atlantic Standard Time - Martinique',
	'America/Puerto_Rico': 'Atlantic Standard Time - Puerto Rico',
	'America/Santo_Domingo': 'Atlantic Standard Time - Santo Domingo',
	'Atlantic/Bermuda': 'Atlantic Time - Bermuda',
	'America/Glace_Bay': 'Atlantic Time - Glace Bay',
	'America/Goose_Bay': 'Atlantic Time - Goose Bay',
	'America/Halifax': 'Atlantic Time - Halifax',
	'America/Moncton': 'Atlantic Time - Moncton',
	'America/Thule': 'Atlantic Time - Thule',
	'America/La_Paz': 'Bolivia Time',
	'America/Guyana': 'Guyana Time',
	'America/Caracas': 'Venezuela Time',
	'America/St_Johns': 'Newfoundland Time',
	'America/Argentina/Buenos_Aires': 'Argentina Standard Time - Buenos Aires',
	'America/Argentina/Catamarca': 'Argentina Standard Time - Catamarca',
	'America/Argentina/Cordoba': 'Argentina Standard Time - Cordoba',
	'America/Argentina/Jujuy': 'Argentina Standard Time - Jujuy',
	'America/Argentina/La_Rioja': 'Argentina Standard Time - La Rioja',
	'America/Argentina/Mendoza': 'Argentina Standard Time - Mendoza',
	'America/Argentina/Rio_Gallegos': 'Argentina Standard Time - Rio Gallegos',
	'America/Argentina/Salta': 'Argentina Standard Time - Salta',
	'America/Argentina/San_Juan': 'Argentina Standard Time - San Juan',
	'America/Argentina/San_Luis': 'Argentina Standard Time - San Luis',
	'America/Argentina/Tucuman': 'Argentina Standard Time - Tucuman',
	'America/Argentina/Ushuaia': 'Argentina Standard Time - Ushuaia',
	'America/Araguaina': 'Brasilia Standard Time - Araguaina',
	'America/Bahia': 'Brasilia Standard Time - Bahia',
	'America/Belem': 'Brasilia Standard Time - Belem',
	'America/Fortaleza': 'Brasilia Standard Time - Fortaleza',
	'America/Maceio': 'Brasilia Standard Time - Maceio',
	'America/Recife': 'Brasilia Standard Time - Recife',
	'America/Santarem': 'Brasilia Standard Time - Santarem',
	'America/Sao_Paulo': 'Brasilia Standard Time - Sao Paulo',
	'America/Santiago': 'Chile Time',
	'Atlantic/Stanley': 'Falkland Islands Standard Time',
	'America/Cayenne': 'French Guiana Time',
	'Antarctica/Palmer': 'Palmer Time',
	'America/Asuncion': 'Paraguay Time',
	'America/Punta_Arenas': 'Punta Arenas Time',
	'Antarctica/Rothera': 'Rothera Time',
	'America/Miquelon': 'St. Pierre & Miquelon Time',
	'America/Paramaribo': 'Suriname Time',
	'America/Montevideo': 'Uruguay Standard Time',
	'America/Nuuk': 'West Greenland Time',
	'America/Noronha': 'Fernando de Noronha Standard Time',
	'Atlantic/South_Georgia': 'South Georgia Time',
	'Atlantic/Azores': 'Azores Time',
	'Atlantic/Cape_Verde': 'Cape Verde Standard Time',
	'America/Scoresbysund': 'East Greenland Time',
	UTC: 'Coordinated Universal Time',
	'Etc/GMT': 'Greenwich Mean Time',
	'Africa/Abidjan': 'Greenwich Mean Time - Abidjan',
	'Africa/Bissau': 'Greenwich Mean Time - Bissau',
	'America/Danmarkshavn': 'Greenwich Mean Time - Danmarkshavn',
	'Africa/Monrovia': 'Greenwich Mean Time - Monrovia',
	'Atlantic/Reykjavik': 'Greenwich Mean Time - Reykjavik',
	'Africa/Sao_Tome': 'Greenwich Mean Time - SÃ£o TomÃ©',
	'Europe/Dublin': 'Ireland Time',
	'Antarctica/Troll': 'Troll Time',
	'Europe/London': 'United Kingdom Time',
	'Atlantic/Canary': 'Western European Time - Canary',
	'Atlantic/Faroe': 'Western European Time - Faroe',
	'Europe/Lisbon': 'Western European Time - Lisbon',
	'Atlantic/Madeira': 'Western European Time - Madeira',
	'Africa/Algiers': 'Central European Standard Time - Algiers',
	'Africa/Tunis': 'Central European Standard Time - Tunis',
	'Europe/Amsterdam': 'Central European Time - Amsterdam',
	'Europe/Andorra': 'Central European Time - Andorra',
	'Europe/Belgrade': 'Central European Time - Belgrade',
	'Europe/Berlin': 'Central European Time - Berlin',
	'Europe/Brussels': 'Central European Time - Brussels',
	'Europe/Budapest': 'Central European Time - Budapest',
	'Africa/Ceuta': 'Central European Time - Ceuta',
	'Europe/Copenhagen': 'Central European Time - Copenhagen',
	'Europe/Gibraltar': 'Central European Time - Gibraltar',
	'Europe/Luxembourg': 'Central European Time - Luxembourg',
	'Europe/Madrid': 'Central European Time - Madrid',
	'Europe/Malta': 'Central European Time - Malta',
	'Europe/Monaco': 'Central European Time - Monaco',
	'Europe/Oslo': 'Central European Time - Oslo',
	'Europe/Paris': 'Central European Time - Paris',
	'Europe/Prague': 'Central European Time - Prague',
	'Europe/Rome': 'Central European Time - Rome',
	'Europe/Stockholm': 'Central European Time - Stockholm',
	'Europe/Tirane': 'Central European Time - Tirane',
	'Europe/Vienna': 'Central European Time - Vienna',
	'Europe/Warsaw': 'Central European Time - Warsaw',
	'Europe/Zurich': 'Central European Time - Zurich',
	'Africa/Casablanca': 'Morocco Time',
	'Africa/Lagos': 'West Africa Standard Time - Lagos',
	'Africa/Ndjamena': 'West Africa Standard Time - Ndjamena',
	'Africa/El_Aaiun': 'Western Sahara Time',
	'Africa/Juba': 'Central Africa Time - Juba',
	'Africa/Khartoum': 'Central Africa Time - Khartoum',
	'Africa/Maputo': 'Central Africa Time - Maputo',
	'Africa/Windhoek': 'Central Africa Time - Windhoek',
	'Africa/Cairo': 'Eastern European Standard Time - Cairo',
	'Europe/Kaliningrad': 'Eastern European Standard Time - Kaliningrad',
	'Africa/Tripoli': 'Eastern European Standard Time - Tripoli',
	'Europe/Athens': 'Eastern European Time - Athens',
	'Asia/Beirut': 'Eastern European Time - Beirut',
	'Europe/Bucharest': 'Eastern European Time - Bucharest',
	'Europe/Chisinau': 'Eastern European Time - Chisinau',
	'Asia/Gaza': 'Eastern European Time - Gaza',
	'Asia/Hebron': 'Eastern European Time - Hebron',
	'Europe/Helsinki': 'Eastern European Time - Helsinki',
	'Europe/Kiev': 'Eastern European Time - Kyiv',
	'Asia/Nicosia': 'Eastern European Time - Nicosia',
	'Europe/Riga': 'Eastern European Time - Riga',
	'Europe/Sofia': 'Eastern European Time - Sofia',
	'Europe/Tallinn': 'Eastern European Time - Tallinn',
	'Europe/Uzhgorod': 'Eastern European Time - Uzhhorod',
	'Europe/Vilnius': 'Eastern European Time - Vilnius',
	'Europe/Zaporozhye': 'Eastern European Time - Zaporozhye',
	'Asia/Famagusta': 'Famagusta Time',
	'Asia/Jerusalem': 'Israel Time',
	'Africa/Johannesburg': 'South Africa Standard Time',
	'Asia/Baghdad': 'Arabian Standard Time - Baghdad',
	'Asia/Qatar': 'Arabian Standard Time - Qatar',
	'Asia/Riyadh': 'Arabian Standard Time - Riyadh',
	'Africa/Nairobi': 'East Africa Time',
	'Asia/Amman': 'Jordan Time',
	'Europe/Kirov': 'Kirov Time',
	'Europe/Minsk': 'Moscow Standard Time - Minsk',
	'Europe/Moscow': 'Moscow Standard Time - Moscow',
	'Europe/Simferopol': 'Moscow Standard Time - Simferopol',
	'Asia/Damascus': 'Syria Time',
	'Europe/Istanbul': 'Turkey Time',
	'Europe/Volgograd': 'Volgograd Standard Time',
	'Asia/Tehran': 'Iran Time',
	'Asia/Yerevan': 'Armenia Standard Time',
	'Europe/Astrakhan': 'Astrakhan Time',
	'Asia/Baku': 'Azerbaijan Standard Time',
	'Asia/Tbilisi': 'Georgia Standard Time',
	'Asia/Dubai': 'Gulf Standard Time',
	'Indian/Mauritius': 'Mauritius Standard Time',
	'Indian/Reunion': 'RÃ©union Time',
	'Europe/Samara': 'Samara Standard Time',
	'Europe/Saratov': 'Saratov Time',
	'Indian/Mahe': 'Seychelles Time',
	'Europe/Ulyanovsk': 'Ulyanovsk Time',
	'Asia/Kabul': 'Afghanistan Time',
	'Indian/Kerguelen': 'French Southern & Antarctic Time',
	'Indian/Maldives': 'Maldives Time',
	'Antarctica/Mawson': 'Mawson Time',
	'Asia/Karachi': 'Pakistan Standard Time',
	'Asia/Dushanbe': 'Tajikistan Time',
	'Asia/Ashgabat': 'Turkmenistan Standard Time',
	'Asia/Samarkand': 'Uzbekistan Standard Time - Samarkand',
	'Asia/Tashkent': 'Uzbekistan Standard Time - Tashkent',
	'Asia/Aqtau': 'West Kazakhstan Time - Aqtau',
	'Asia/Aqtobe': 'West Kazakhstan Time - Aqtobe',
	'Asia/Atyrau': 'West Kazakhstan Time - Atyrau',
	'Asia/Oral': 'West Kazakhstan Time - Oral',
	'Asia/Qyzylorda': 'West Kazakhstan Time - Qyzylorda',
	'Asia/Yekaterinburg': 'Yekaterinburg Standard Time',
	'Asia/Colombo': 'India Standard Time - Colombo',
	'Asia/Kolkata': 'India Standard Time - Kolkata',
	'Asia/Kathmandu': 'Nepal Time',
	'Asia/Dhaka': 'Bangladesh Standard Time',
	'Asia/Thimphu': 'Bhutan Time',
	'Asia/Almaty': 'East Kazakhstan Time - Almaty',
	'Asia/Qostanay': 'East Kazakhstan Time - Kostanay',
	'Indian/Chagos': 'Indian Ocean Time',
	'Asia/Bishkek': 'Kyrgyzstan Time',
	'Asia/Omsk': 'Omsk Standard Time',
	'Asia/Urumqi': 'Urumqi Time',
	'Antarctica/Vostok': 'Vostok Time',
	'Indian/Cocos': 'Cocos Islands Time',
	'Asia/Yangon': 'Myanmar Time',
	'Asia/Barnaul': 'Barnaul Time',
	'Indian/Christmas': 'Christmas Island Time',
	'Antarctica/Davis': 'Davis Time',
	'Asia/Hovd': 'Hovd Standard Time',
	'Asia/Bangkok': 'Indochina Time - Bangkok',
	'Asia/Ho_Chi_Minh': 'Indochina Time - Ho Chi Minh City',
	'Asia/Krasnoyarsk': 'Krasnoyarsk Standard Time - Krasnoyarsk',
	'Asia/Novokuznetsk': 'Krasnoyarsk Standard Time - Novokuznetsk',
	'Asia/Novosibirsk': 'Novosibirsk Standard Time',
	'Asia/Tomsk': 'Tomsk Time',
	'Asia/Jakarta': 'Western Indonesia Time - Jakarta',
	'Asia/Pontianak': 'Western Indonesia Time - Pontianak',
	'Australia/Perth': 'Australian Western Standard Time',
	'Asia/Brunei': 'Brunei Darussalam Time',
	'Asia/Makassar': 'Central Indonesia Time',
	'Asia/Macau': 'China Standard Time - Macao',
	'Asia/Shanghai': 'China Standard Time - Shanghai',
	'Asia/Hong_Kong': 'Hong Kong Standard Time',
	'Asia/Irkutsk': 'Irkutsk Standard Time',
	'Asia/Kuala_Lumpur': 'Malaysia Time - Kuala Lumpur',
	'Asia/Kuching': 'Malaysia Time - Kuching',
	'Asia/Manila': 'Philippine Standard Time',
	'Asia/Singapore': 'Singapore Standard Time',
	'Asia/Taipei': 'Taipei Standard Time',
	'Asia/Choibalsan': 'Ulaanbaatar Standard Time - Choibalsan',
	'Asia/Ulaanbaatar': 'Ulaanbaatar Standard Time - Ulaanbaatar',
	'Australia/Eucla': 'Australian Central Western Standard Time',
	'Asia/Dili': 'East Timor Time',
	'Asia/Jayapura': 'Eastern Indonesia Time',
	'Asia/Tokyo': 'Japan Standard Time',
	'Asia/Pyongyang': 'Korean Standard Time - Pyongyang',
	'Asia/Seoul': 'Korean Standard Time - Seoul',
	'Pacific/Palau': 'Palau Time',
	'Asia/Chita': 'Yakutsk Standard Time - Chita',
	'Asia/Khandyga': 'Yakutsk Standard Time - Khandyga',
	'Asia/Yakutsk': 'Yakutsk Standard Time - Yakutsk',
	'Australia/Darwin': 'Australian Central Standard Time',
	'Australia/Brisbane': 'Australian Eastern Standard Time - Brisbane',
	'Australia/Lindeman': 'Australian Eastern Standard Time - Lindeman',
	'Pacific/Guam': 'Chamorro Standard Time',
	'Pacific/Chuuk': 'Chuuk Time',
	'Pacific/Port_Moresby': 'Papua New Guinea Time',
	'Asia/Ust-Nera': 'Vladivostok Standard Time - Ust-Nera',
	'Asia/Vladivostok': 'Vladivostok Standard Time - Vladivostok',
	'Australia/Adelaide': 'Central Australia Time - Adelaide',
	'Australia/Broken_Hill': 'Central Australia Time - Broken Hill',
	'Pacific/Bougainville': 'Bougainville Time',
	'Antarctica/Casey': 'Casey Time',
	'Australia/Hobart': 'Eastern Australia Time - Hobart',
	'Antarctica/Macquarie': 'Eastern Australia Time - Macquarie',
	'Australia/Melbourne': 'Eastern Australia Time - Melbourne',
	'Australia/Sydney': 'Eastern Australia Time - Sydney',
	'Pacific/Kosrae': 'Kosrae Time',
	'Australia/Lord_Howe': 'Lord Howe Time',
	'Asia/Magadan': 'Magadan Standard Time',
	'Pacific/Noumea': 'New Caledonia Standard Time',
	'Pacific/Pohnpei': 'Ponape Time',
	'Asia/Sakhalin': 'Sakhalin Standard Time',
	'Pacific/Guadalcanal': 'Solomon Islands Time',
	'Asia/Srednekolymsk': 'Srednekolymsk Time',
	'Pacific/Efate': 'Vanuatu Standard Time',
	'Asia/Anadyr': 'Anadyr Standard Time',
	'Pacific/Fiji': 'Fiji Standard Time',
	'Pacific/Tarawa': 'Gilbert Islands Time',
	'Pacific/Kwajalein': 'Marshall Islands Time - Kwajalein',
	'Pacific/Majuro': 'Marshall Islands Time - Majuro',
	'Pacific/Nauru': 'Nauru Time',
	'Pacific/Norfolk': 'Norfolk Island Time',
	'Asia/Kamchatka': 'Petropavlovsk-Kamchatski Standard Time',
	'Pacific/Funafuti': 'Tuvalu Time',
	'Pacific/Wake': 'Wake Island Time',
	'Pacific/Wallis': 'Wallis & Futuna Time',
	'Pacific/Apia': 'Apia Standard Time',
	'Pacific/Auckland': 'New Zealand Time',
	'Pacific/Kanton': 'Phoenix Islands Time',
	'Pacific/Fakaofo': 'Tokelau Time',
	'Pacific/Tongatapu': 'Tonga Standard Time',
	'Pacific/Chatham': 'Chatham Time',
	'Pacific/Kiritimati': 'Line Islands Time',
	'Etc/GMT+2': 'Coordinated Universal Time-02',
	'Etc/GMT+12': 'International Date line West',
	'Etc/GMT+11': 'Coordinated Universal Time-11',
	'Etc/GMT+9': 'Coordinated Universal Time-09',
	'Etc/GMT+8': 'Coordinated Universal Time-08',
	'America/Indianapolis': 'Indiana (East)',
	'America/Buenos_Aires': 'City of Buenos Aires',
	'America/Godthab': 'Greenland',
	'Asia/Katmandu': 'Kathmandu',
	'Asia/Rangoon': 'Yangon (Rangoon)',
	'Etc/GMT-12': 'Coordinated Universal Time-12',
	'Etc/GMT-13': 'Coordinated Universal Time-13',
};

export default allTimezones;
