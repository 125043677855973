import { INIT_INBOX_READ, FAIL_INBOX_READ,SUCCESS_INBOX_READ,INIT_INBOX_ADMIN_TEACHER_READ,SUCCESS_INBOX_ADMIN_TEACHER_READ,FAIL_INBOX_ADMIN_TEACHER_READ } from "redux/constants/inbox";

const initialState = {
    loading: true,
    inbox_read_count: 0,
    teacher_admin_inbox_read_count: 0,
};

const inboxReducer = (state = initialState, action: any) => {
  const { type, payload, error } = action;
  switch (type) {
    case INIT_INBOX_READ:
      return {
        ...state,
        loading: true,
        
      };
    case SUCCESS_INBOX_READ:
      return {
        ...state,
        loading: false,
        inbox_read_count: payload,
      };
    case FAIL_INBOX_READ:
      return {
        ...state,
        loading: false,
      };

      case INIT_INBOX_ADMIN_TEACHER_READ:
      return {
        ...state,
        loading: true,
        
      };
    case SUCCESS_INBOX_ADMIN_TEACHER_READ:
      return {
        ...state,
        loading: false,
        teacher_admin_inbox_read_count: payload,
      };
    case FAIL_INBOX_ADMIN_TEACHER_READ:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
  }


export default inboxReducer;
