import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import { rootReducer } from './reducers/';

const createHistory = require('history').createBrowserHistory;
const history = createHistory();
const RouterMiddleware = routerMiddleware(history);

const initialState = {};
const middleware = [RouterMiddleware, thunk];

const store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));

export { history, store };
