import {
	FETCH_SUBSCRIPTION_DATA_SUCCESS,
	FETCH_SUBSCRIPTION_DATA_FAILURE,
	FETCH_COMMUNITY_CONTENT_SUCCESS,
	FETCH_COMMUNITY_CONTENT_FAILURE,
	FETCH_COMMUNITY_CONTENT_INI,
} from '../constants/subscription';

// Initial state for the subscription reducer
const initialState = {
	isEnrolled: false, // Indicates whether the user is enrolled or not
	content: {}, // Stores the fetched community content
	error: null, // Holds any error occurred during fetching data
	loading: false, // Indicates whether data is currently being loaded
};

// Reducer function for subscription-related actions
const subscriptionReducer = (state = initialState, action: any) => {
	switch (action.type) {
		case FETCH_SUBSCRIPTION_DATA_SUCCESS:
			return {
				...state,
				isEnrolled: true, // Update enrollment status to true upon successful data fetch
				error: null, // Clear any previous error message upon success
			};
		case FETCH_SUBSCRIPTION_DATA_FAILURE:
			return {
				...state,
				isEnrolled: false, // Update enrollment status to false due to data fetch failure
				error: action.payload, // Store the error message from the failed request
			};
		case FETCH_COMMUNITY_CONTENT_INI:
			return {
				...state,
				loading: true, // Set loading state to false as data fetching is completed
			};
		case FETCH_COMMUNITY_CONTENT_SUCCESS:
			return {
				...state,
				content: action.payload, // Update the content with the fetched data upon success
				error: null, // Clear any previous error message upon success
				loading: false, // Set loading state to false as data fetching is completed
			};
		case FETCH_COMMUNITY_CONTENT_FAILURE:
			return {
				...state,
				error: action.payload, // Store the error message from the failed request
				loading: false, // Set loading state to false as data fetching failed
			};
		default:
			return state; // Return the current state if the action type is not recognized
	}
};

export default subscriptionReducer; // Export the subscription reducer function
