import { combineReducers } from 'redux';
import authReducer from './auth';
import alertReducer from './alert';
import roomReducer from './room';
import commonReducer from './common';
import adminReducer from './admin';
import localeReducer from './locale';
import sidebarReducer from './sidebar';
import inboxReducer from './Inbox';
import dashboardReducer from './dashboard';
import subscriptionReducer from './subscription';
import zoomReducers from './zoom';

export const rootReducer = combineReducers({
	auth: authReducer,
	dashboard: dashboardReducer,
	alert: alertReducer,
	room: roomReducer,
	common: commonReducer,
	admin: adminReducer,
	locale: localeReducer,
	sidebar: sidebarReducer,
	inbox: inboxReducer,
	subscription: subscriptionReducer,
	zoom: zoomReducers,
});

export type RootState = ReturnType<typeof rootReducer>;
