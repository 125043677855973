import { CHANGE_LOCALE } from "redux/constants/locale";

const initialState = {
  locale: "en",
};

const localeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CHANGE_LOCALE:
      return {
        locale: action.payload,
      };

    default:
      return state;
  }
};

export default localeReducer;
