import { useTranslation } from 'react-i18next';
import './Fallback.css';

const Fallback = ({ componentStack, error }: any) => {
	const { t } = useTranslation('notFound');
	return (
		<section className="fallback">
			<header className="fallback__header">
				<h3 className="fallback__title">{t('errorOccurred')}</h3>
			</header>
			<div className="fallback__body">
				<p>
					<strong>{t('error')}:</strong> {error.toString()}
				</p>
				<p>
					<strong>{t('stacktrace')}:</strong> {componentStack}
				</p>
			</div>
		</section>
	);
};

export default Fallback;
