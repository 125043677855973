import axios from 'axios';
import { log } from 'utils/logWrapper';

export const getResposeType = (errorResponse: any) => {
	const message = errorResponse && errorResponse.data && errorResponse.data.message ? errorResponse.data.message : null;

	switch (errorResponse.status) {
		case 200:
			return { statusCode: 200, type: 'success', message: message };
		case 201:
			return { statusCode: 201, type: 'success', message: message };
		case 204:
			return { statusCode: 204, type: 'success', message: message };
		case 400:
			return { statusCode: 400, type: 'error', message: message };
		case 401: // return refreshUnauthorisedToken(error)
			break;
		case 403:
			return { statusCode: 403, type: 'error', message: message };
		case 404:
			return { statusCode: 404, type: 'error', message: message };
		case 500:
			return { statusCode: 500, type: 'error', message: message };
		case 502:
			return { statusCode: 502, type: 'error', message: message };
		default:
			return { statusCode: 'default', type: 'error', message: 'Something went wrong..!' };
	}
};

const refreshUnauthorisedToken = async (error: any) => {
	let refresh = false;
	log('error......', error);
	if (error.response.status === 401 && !refresh) {
		axios.defaults.headers.common['x-auth-token'] = `Bearer ${localStorage.getItem('refreshToken')}`;
		axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('refreshToken')}`;
		refresh = true;
		const response = await axios.post(process.env.REACT_APP_API_URL + 'api/refreshToken', {
			refreshToken: localStorage.getItem('refreshToken'),
		});

		if (response.status === 200 || response.status === 201) {
			localStorage.setItem('token', response.data['accessToken']);
			error.config._retry = true;
			error.config.headers['x-auth-token'] = `Bearer ${response.data['accessToken']}`;
			error.config.headers['Authorization'] = `Bearer ${response.data['accessToken']}`;

			delete axios.defaults.headers.common['x-auth-token'];
			delete axios.defaults.headers.common['Authorization'];
			axios.defaults.headers.common['x-auth-token'] = `Bearer ${response.data['accessToken']}`;
			axios.defaults.headers.common['Authorization'] = `Bearer ${response.data['accessToken']}`;
			return axios(error.config);
		}
	}
	refresh = false;
};
