import Sidebar from 'component/layout/sidebar/Sidebar';
import { ROLE_TYPE } from './constant_helper';
import AccessDenied from 'component/screen/notFound/AccessDenied';

const PrivateRoute = (props: any) => {
	const isValidRoutes = props?.rolesPermitted?.some((value: any) => Object.values(ROLE_TYPE).includes(value));

	return (
		<div className="container-fluid page-body-wrapper ">
			{(props?.rolesPermitted?.includes('teacher') || props?.rolesPermitted?.includes('admin')) && <Sidebar />}

			{isValidRoutes ? props.elementComponant : <AccessDenied />}
		</div>
	);
};

export default PrivateRoute;
