import axios from 'axios';
import {
	REGISTER_REQ,
	REGISTER_SUCCESS,
	REGISTER_FAIL,
	USER_LOAD_REQ,
	USER_LOADED,
	AUTH_ERROR,
	LOGIN_REQ,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT_SUCCESS,
	CLEAR_PROFILE,
	UPDATE_USER_REQ,
	UPDATE_USER_SUCCESS,
	UPDATE_USER_FAIL,
	UPLOAD_PIC_REQ,
	UPLOAD_PIC_SUCCESS,
	UPLOAD_PIC_FAIL,
	FORGOT_PASSWORD_REQ,
	FORGOT_PASSWORD_SUCCESS,
	FORGOT_PASSWORD_FAIL,
	RESET_PASSWORD_REQ,
	RESET_PASSWORD_SUCCESS,
	RESET_PASSWORD_FAIL,
	VERIFY_EMAIL_REQ,
	VERIFY_EMAIL_SUCCESS,
	VERIFY_EMAIL_FAIL,
	SUBSCRIBED_USER_EXISTS,
	SUBSCRIBED_USER_NOT_EXISTS,
	IP_ADDRESS_SUCCESS,
	IP_ADDRESS_FAIL,
} from '../constants/auth';

import { setAlert } from '../actions/alert';
import setAuthToken from '../../utils/setAuthToken';
import { toast } from 'react-toastify';
import { log } from 'utils/logWrapper';
import { getAllTimezoneList, getTimeZoneAbbreviation } from '../../component/screen/dyadSchedule/TimeZoneHelper';
import { DeleteAPI, PostAPI, UpdateAPI } from 'utils/API';
import { FAIL_INBOX_READ, INIT_INBOX_READ, SUCCESS_INBOX_READ } from 'redux/constants/inbox';
const timeZoneOptionList: any = getAllTimezoneList();

export const loadedUser = () => async (dispatch: any) => {
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}
	try {
		if (localStorage.token) {
			dispatch({ type: USER_LOAD_REQ });

			const { data } = await axios.get(process.env.REACT_APP_API_URL + 'api/auth');

			if (data.appLabel && data.appLabel.value) {
				axios.defaults.headers.common['Accept-Language'] = data.appLabel.value;
			}

			let countryLocalTimeArea = data.countryLocalTimeArea;
			let timezone = data.timezone;
			// let utcOffset = data.utcOffset;

			if (!data.countryLocalTimeArea) {
				const defaultTimeZone = getTimeZoneAbbreviation(timeZoneOptionList, '');
				if (defaultTimeZone && defaultTimeZone !== undefined) {
					timezone = defaultTimeZone.abbrev;
					countryLocalTimeArea = defaultTimeZone.value;
					// utcOffset = defaultTimeZone.offset;

					await axios.post(process.env.REACT_APP_API_URL + `api/users/timeZone`, {
						timezone: defaultTimeZone.abbrev,
						countryLocalTimeArea: defaultTimeZone.value,
						// utcOffset: defaultTimeZone.offset,
						flag: false,
					});
				}
			}

			dispatch(testAPIIp());

			dispatch({
				type: USER_LOADED,
				payload: { ...data, countryLocalTimeArea, timezone },
			});
		}
	} catch (error) {
		dispatch({
			type: AUTH_ERROR,
		});
	}
};

type Register = {
	firstName: String;
	lastName?: String;
	email: String;
	password: String;
	timezone: String;
	countryLocalTimeArea: String;
	// utcOffset: Number;
	language: Array<Object>;
	roles: Array<String>;
	appLabel: Object;
	phoneNumber: String;
	country: String;
	state: String;
	countryFlag: String;
	countryCode: String;
	adminId: String;
};

export const registerByAdmin = (params: Register, flag: boolean) => async (dispatch: any) => {
	const {
		firstName,
		lastName,
		email,
		password,
		timezone,
		countryLocalTimeArea,
		// utcOffset,
		language,
		appLabel,
		phoneNumber,
		roles,
		country,
		state,
		countryFlag,
		countryCode,
		adminId,
	} = params;

	const config = {
		headers: {
			'content-type': 'application/json',
		},
	};

	const body = JSON.stringify({
		firstName,
		lastName,
		email: email.toLowerCase(),
		password,
		timezone,
		countryLocalTimeArea,
		// utcOffset,
		language,
		appLabel,
		phoneNumber,
		roles,
		country,
		state,
		countryFlag,
		countryCode,
		adminId,
	});
	try {
		if (flag) dispatch({ type: REGISTER_REQ });
		const response = await PostAPI('api/users', body, '', config);
		if (flag) {
			dispatch({
				type: REGISTER_SUCCESS,
				payload: response.data,
			});
			dispatch(setAlert('Register Successfully', 'success'));
			dispatch(loadedUser());
		}
	} catch (error: any) {
		const errors = error?.response?.data?.errors;

		if (errors) {
			errors.forEach((er: any) => toast.error(er.msg));
		}
		dispatch({
			type: REGISTER_FAIL,
			error: errors,
		});
	}
};

export const login = (email: string, password: string) => async (dispatch: any) => {
	const config = {
		headers: {
			'content-type': 'application/json',
		},
	};
	const body = JSON.stringify({ email, password });
	try {
		dispatch({ type: LOGIN_REQ });
		const response = await PostAPI('api/auth/login', body, '', config);
		dispatch({
			type: LOGIN_SUCCESS,
			payload: response.data,
		});
		localStorage.setItem('onAdminPanal', 'user');
		dispatch(loadedUser());
	} catch (error: any) {
		const errors = error?.response?.data?.errors;
		log('', errors);
		if (errors) {
			errors.forEach((er: any) => toast.error(er.msg));
		}
		dispatch({
			type: LOGIN_FAIL,
			error: errors,
		});
	}
};

export const loginFromAdmin = (userId: string) => async (dispatch: any) => {
	const config = {
		headers: {
			'content-type': 'application/json',
		},
	};
	try {
		dispatch({ type: LOGIN_REQ });
		const res = await axios.post(process.env.REACT_APP_API_URL + 'api/auth/loginFromAdmin', { userId }, config);

		dispatch({
			type: LOGIN_SUCCESS,
			payload: res.data,
		});
		localStorage.setItem('onAdminPanal', 'user');
		dispatch(loadedUser());
	} catch (error: any) {
		const errors = error?.response?.data?.errors;
		log('', errors);
		if (errors) {
			errors.forEach((er: any) => toast.error(er.msg));
		}
		dispatch({
			type: LOGIN_FAIL,
			error: errors,
		});
	}
};

export const logout = (id: String) => async (dispatch: any) => {
	await DeleteAPI('api/refreshToken', id);
	dispatch({
		type: LOGOUT_SUCCESS,
	});
	dispatch({
		type: CLEAR_PROFILE,
	});
	localStorage.clear();
	window.location.href = process.env.REACT_APP_BASE_URL + 'login';
};

export const UpdateUser = (userData: any, userId: any) => async (dispatch: any, getState: any) => {
	const config = {
		headers: {
			'content-type': 'application/json',
		},
	};
	const id = userId ? userId : getState().auth.user._id;

	try {
		dispatch({ type: UPDATE_USER_REQ });
		const response = await UpdateAPI('api/users/', userData, id, config);
		dispatch({
			type: UPDATE_USER_SUCCESS,
		});
		dispatch(loadedUser());

		return new Promise((resolve, reject) => {
			resolve(response);
		});
	} catch (error: any) {
		const errors = error?.response?.data?.errors;
		log('', errors);
		if (errors) {
			errors.forEach((er: any) => toast.error(er.msg));
		}
		dispatch({
			type: UPDATE_USER_FAIL,
		});
	}
};

export const updateUserTimeZone = (userData: any) => async (dispatch: any) => {
	try {
		const response = await PostAPI('api/users/timeZone', userData, '', '');
		dispatch(loadedUser());
		return new Promise((resolve, reject) => {
			resolve(response);
		});
	} catch (error: any) {
		const errors = error?.response?.data?.errors;
		log('', errors);
		if (errors) {
			errors.forEach((er: any) => toast.error(er.msg));
		}
	}
};

export const ChangePassword = (object: any) => async (dispatch: any, getState: any) => {
	const config = {
		headers: {
			'content-type': 'application/json',
		},
	};

	try {
		const res = await axios.put(process.env.REACT_APP_API_URL + `api/auth/changePassword`, object, config);

		return new Promise((resolve, reject) => {
			resolve(res);
		});
	} catch (error: any) {
		const errors = error?.response?.data?.errors;
		log('', errors);
		if (errors) {
			errors.forEach((er: any) => toast.error(er.msg));
		}
		dispatch({
			type: UPDATE_USER_FAIL,
		});
	}
};

export const uploadPic = (profilePicData: any, userId: any) => async (dispatch: any, getState: any) => {
	let formData = new FormData();
	formData.append('upload', profilePicData);
	const id = userId ? userId : getState().auth.user._id;
	formData.append('id', id);
	try {
		dispatch({ type: UPLOAD_PIC_REQ });
		const response = await PostAPI('api/image-uploader/', formData, id, '');
		if (response) {
			dispatch({
				type: UPLOAD_PIC_SUCCESS,
			});
			dispatch(loadedUser());
		}
	} catch (error: any) {
		const errors = error?.response?.data?.errors;
		log('', errors);
		if (errors) {
			errors.forEach((er: any) => toast.error(er.msg));
		}
		dispatch({
			type: UPLOAD_PIC_FAIL,
		});
	}
};

export const forgotPassword = (email: string) => async (dispatch: any, getState: any) => {
	const config = {
		headers: {
			'content-type': 'application/json',
		},
	};

	try {
		dispatch({ type: FORGOT_PASSWORD_REQ });
		const res = await axios.get(process.env.REACT_APP_API_URL + `api/users/resetPassword/email/${email}`, config);
		if (res.status) {
			dispatch({
				type: FORGOT_PASSWORD_SUCCESS,
			});
		} else {
			dispatch({
				type: FORGOT_PASSWORD_FAIL,
			});
		}

		return new Promise((resolve, reject) => {
			resolve(res);
		});
	} catch (error: any) {
		const errors = error?.response?.data?.errors;
		log('', errors);
		if (errors) {
			errors.forEach((er: any) => toast.error(er.msg));
		}
		dispatch({
			type: FORGOT_PASSWORD_FAIL,
		});
	}
};

export const resetPassword = (password: string, encodedId: string, flag: boolean) => async (dispatch: any) => {
	const config = {
		headers: {
			'content-type': 'application/json',
		},
	};

	try {
		dispatch({ type: RESET_PASSWORD_REQ });
		const res = await axios.put(
			process.env.REACT_APP_API_URL + `api/auth/resetPassword`,
			{ encodedId, password, flag },
			config
		);

		dispatch({
			type: RESET_PASSWORD_SUCCESS,
		});
		return new Promise((resolve, reject) => {
			resolve(res);
		});
	} catch (error: any) {
		const errors = error?.response?.data?.errors;
		log('', errors);
		if (errors) {
			errors.forEach((er: any) => toast.error(er.msg));
		}

		dispatch({
			type: RESET_PASSWORD_FAIL,
		});
	}
};

export const verifyEmail = (encodedId: string) => async (dispatch: any) => {
	const config = {
		headers: {
			'content-type': 'application/json',
		},
	};

	try {
		dispatch({ type: VERIFY_EMAIL_REQ });
		const res = await axios.get(
			process.env.REACT_APP_API_URL + `api/users/verifyEmail/${encodedId}`,

			config
		);
		if (res.status) {
			dispatch({
				type: VERIFY_EMAIL_SUCCESS,
			});
		} else {
			dispatch({
				type: VERIFY_EMAIL_FAIL,
			});
		}

		return new Promise((resolve, reject) => {
			resolve(res);
		});
	} catch (error: any) {
		const errors = error?.response?.data?.errors;
		log('', errors);
		if (errors) {
			errors.forEach((er: any) => toast.error(er.msg));
		}
		dispatch({
			type: VERIFY_EMAIL_FAIL,
		});
	}
};

export const loginUsingGmail = (userData: any) => async (dispatch: any) => {
	dispatch({ type: REGISTER_REQ });

	try {
		let object = {
			firstName: userData.givenName,
			lastName: userData.familyName,
			email: userData.email,
		};

		const config = {
			headers: {
				'content-type': 'application/json',
			},
		};
		const { data } = await axios.post(process.env.REACT_APP_API_URL + `api/users/googleLogin`, object, config);
		dispatch({
			type: REGISTER_SUCCESS,
			payload: data,
		});
		dispatch(loadedUser());
	} catch (error: any) {
		log('', error);
	}
};

export const editPairing = (userData: any) => async (dispatch: any) => {
	try {
		await axios.patch(process.env.REACT_APP_API_URL + `api/cohort/pairing-user`, userData);
	} catch (error) {
		console.log(error);
	}
};

export const createPairing = (userData: any) => async (dispatch: any) => {
	try {
		await axios.post(process.env.REACT_APP_AWAIT_API_URL + `api/cocoon/create-pairing`, userData);
	} catch (error) {
		console.log(error);
	}
};

export const createUserSubscription = (object: any) => async (dispatch: any) => {
	try {
		const response = await axios.post(`${process.env.REACT_APP_API_URL}api/community`, object);
		if (response?.status === 201) {
			dispatch({
				type: SUBSCRIBED_USER_EXISTS,
			});
			return;
		}
		dispatch({
			type: SUBSCRIBED_USER_NOT_EXISTS,
		});
	} catch (error) {
		console.log(error);
	}
};

export const testAPIIp = () => async (dispatch: any) => {
	const config = {
		headers: {
			'content-type': 'application/json',
		},
	};

	try {
		const res: any = await axios.get(process.env.REACT_APP_API_URL + `api/auth/test/ip`, config);
		if (res.data) {
			dispatch({
				type: IP_ADDRESS_SUCCESS,
				payload: res.data?.IP_ADDRESS,
			});
		} else {
			dispatch({
				type: IP_ADDRESS_FAIL,
			});
		}

		return new Promise((resolve, reject) => {
			resolve(res.data?.IP_ADDRESS);
		});
	} catch (error: any) {
		dispatch({
			type: IP_ADDRESS_FAIL,
		});
	}
};

//TODO @sachin, Here we added return null deliberately to avoid inbox count feature as we're not using it.
//we'll take care of it in next iteration.
export const inboxCount = (userId: string) => async (dispatch: any) => {
	try {
		return null;   //as we're not using inbox right now, whenever we'll resume just need to remove this return null statement.
		dispatch({ type: INIT_INBOX_READ });
		const response = await axios.get(`${process.env.REACT_APP_API_URL}api/inbox/${userId}`);

		const data = response?.data?.data || [];
		const count = data.filter(
			(d: any) => !d.read || d.reply.some((r: any) => r.sender !== userId && !r.read)
		).length;

		dispatch({ type: SUCCESS_INBOX_READ, payload: count });
	} catch (error) {
		dispatch({ type: FAIL_INBOX_READ });
	}
};
