// import React from 'react';

const Loader = () => {
	return (
		<div
			style={{
				height: '100%',
				display: 'flex',
				flex: 1,
				alignItems: 'center',
				justifyContent: 'center',
				position: 'fixed',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				color: '#2db3b6',
				zIndex: 9999,
			}}>
			<div className="spinner-border" role="status">
				<span className="sr-only">Loading...</span>
			</div>
		</div>
	);
};

export default Loader;
