import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ErrorBoundary } from 'react-error-boundary';
import Fallback from './component/layout/fallback/Fallback';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { Provider } from '@rollbar/react';
import { log } from 'utils/logWrapper';
const App = () => {
	const userInfo = useSelector((state: RootState) => state.auth.user);
	const rollbarConfig = {
		accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
		environment: process.env.REACT_APP_ROLLBAR_ENVIRONMENT,
		captureUncaught: true,
		captureUnhandledRejections: true,
		payload: {
			client: {
				javascript: {
					code_version: '1.0.0',
					source_map_enabled: true,
				},
			},
			person: {
				id: userInfo && userInfo['_id'], // required
				username: userInfo && userInfo['firstName'] + ' ' + userInfo['lastName'],
				email: userInfo && userInfo['email'],
			},
		},
	};

	return (
		<BrowserRouter>
			<ToastContainer />
			<ErrorBoundary
				FallbackComponent={Fallback}
				onError={(error: Error, info: { componentStack: string }) => {
					log('', error);
					console.error(info);
				}}>
				<Provider config={rollbarConfig}>
					<AppRoutes />
				</Provider>
			</ErrorBoundary>
		</BrowserRouter>
	);
};

export default App;
