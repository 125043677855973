import React from 'react';
import { ROLE_TYPE } from './constant_helper';

export function componentLoader(lazyComponent: any, attemptsLeft: any): any {
	localStorage.setItem('lazyLoaded', attemptsLeft.toString());
	return new Promise((resolve, reject) => {
		lazyComponent()
			.then((component: any) => {
				resolve(component);
			})
			.catch((error: any) => {
				// let us retry after 1000 ms
				setTimeout(() => {
					let turnLeft = refreshedCount();
					if (turnLeft === null || turnLeft === '1') {
						// not been refreshed yet
						localStorage.setItem('lazyLoaded', '0');
						return window.location.reload(); // refresh the page
					}
					if (turnLeft === '2') {
						componentLoader(lazyComponent, Number(turnLeft) - 1);
					}
					if (Number(turnLeft) === 0) {
						reject(error);
						return;
					}
				}, 500);
			});
	});
}

const refreshedCount = () => {
	const isLoaded = localStorage.getItem('lazyLoaded');
	return isLoaded;
};

export const Login = React.lazy(() => componentLoader(() => import('component/screen/login/Login'), 2));
const Payment = React.lazy(() => componentLoader(() => import('component/screen/payment/Payment'), 2));
export const Registration = React.lazy(() =>
	componentLoader(() => import('component/screen/registration/Registration'), 2)
);
export const NotFound = React.lazy(() => componentLoader(() => import('component/screen/notFound/NotFound'), 2));
export const AccessDenied = React.lazy(() =>
	componentLoader(() => import('component/screen/notFound/AccessDenied'), 2)
);
const Profile = React.lazy(() => componentLoader(() => import('component/screen/profile/Profile'), 2));
const NewDashboard = React.lazy(() => componentLoader(() => import('component/screen/dashboard/NewDashboard'), 2));
const ZoomDashboard = React.lazy(() => componentLoader(() => import('component/screen/dashboard/ZoomDashboard'), 2));

//const Faq = React.lazy(() => componentLoader(() => import('component/screen/faq/Faq'), 2));

const StudentCourse = React.lazy(() =>
	componentLoader(() => import('component/screen/studentCourse/StudentCourse'), 2)
);
const AdminDashboard = React.lazy(() =>
	componentLoader(() => import('component/screen/adminDashboard/AdminDashboard'), 2)
);

const ManageUsers = React.lazy(() => componentLoader(() => import('component/screen/manageUsers/ManageUsers'), 2));
export const RegisterUser = React.lazy(() =>
	componentLoader(() => import('component/screen/registerUser/RegisterUser'), 2)
);

const WelcomeScreen = React.lazy(() =>
	componentLoader(() => import('component/screen/welcomeScreen/WelcomeScreen'), 2)
);
export const ForgotPassword = React.lazy(() =>
	componentLoader(() => import('component/screen/forgotPassword/ForgotPassword'), 2)
);
export const ResetPassword = React.lazy(() =>
	componentLoader(() => import('component/screen/resetPassword/ResetPassword'), 2)
);

const VerifyEmail = React.lazy(() => componentLoader(() => import('component/screen/verifyEmail/VerifyEmail'), 2));
const DyadHistory = React.lazy(() => componentLoader(() => import('component/screen/dyadHistory/DyadHistory'), 2));

const AudioVideoTest = React.lazy(() =>
	componentLoader(() => import('component/screen/audioVideoTest/AudioVideoTest'), 2)
);
const CourseCohortPair = React.lazy(() =>
	componentLoader(() => import('component/screen/createPair/CourseCohortPair'), 2)
);
const ValidateCoursePairing = React.lazy(() =>
	componentLoader(() => import('component/screen/createPair/ValidateCoursePairing'), 2)
);

const DyadSchedule = React.lazy(() => componentLoader(() => import('component/screen/dyadSchedule/DyadSchedule'), 2));

// const Resources = React.lazy(() => componentLoader(() => import('component/screen/resources/Resources'), 2));

const Feedback = React.lazy(() => componentLoader(() => import('component/screen/feedback/Feedback'), 2));
const Messaging = React.lazy(() => componentLoader(() => import('component/screen/messaging/Messaging'), 2));
const CMS = React.lazy(() => componentLoader(() => import('component/screen/cms/CMS'), 2));
const CourseContent = React.lazy(() => componentLoader(() => import('component/screen/cms/CourseContent'), 2));
const AddCMS = React.lazy(() => componentLoader(() => import('component/screen/cms/AddCMS'), 2));
const DashboardAlert = React.lazy(() => componentLoader(() => import('component/screen/messaging/DashboardAlert'), 2));
const Subscription = React.lazy(() => componentLoader(() => import('component/screen/subscription/Subscription'), 2));
const MyCourse = React.lazy(() => componentLoader(() => import('component/screen/studentCourse/MyCourse'), 2));
const ContactUs = React.lazy(() => componentLoader(() => import('component/screen/contactUs/ContactUs'), 2));
export const Footer = React.lazy(() => componentLoader(() => import('component/layout/footer/Footer'), 2));
export const Header = React.lazy(() => componentLoader(() => import('component/layout/header/Header'), 2));
const ImportUsers = React.lazy(() => componentLoader(() => import('component/screen/registerUser/ImportUsers'), 2));
const SubscriptionPopup = React.lazy(() =>
	componentLoader(() => import('component/screen/dashboard/components/subscriptionPopup/subscriptionPopup'), 2)
);
const MySubscription = React.lazy(() =>
	componentLoader(() => import('component/screen/mySubscription/MySubscription'), 2)
);
const WeeklyEnrolled = React.lazy(() =>
	componentLoader(() => import('component/screen/mySubscription/WeeklyEnrolled'), 2)
);
const Community = React.lazy(() => componentLoader(() => import('component/screen/community/Community'), 2));
const SubscriptionPairingList = React.lazy(() =>
	componentLoader(() => import('component/screen/createPair/SubscriptionPairing'), 2)
);

const PaymentSuccess = React.lazy(() => componentLoader(() => import('component/screen/payment/PaymentSuccess'), 2));

const UserInbox = React.lazy(() => componentLoader(() => import('component/screen/inbox/UserInbox'), 2));

const TeacherInbox = React.lazy(() => componentLoader(() => import('component/screen/inbox/TeacherInbox'), 2));

const AdminInbox = React.lazy(() => componentLoader(() => import('component/screen/inbox/AdminInbox'), 2));
const CommunityEnrollment = React.lazy(() =>
	componentLoader(() => import('component/screen/createPair/CommunityEnrollment'), 2)
);

const ManageAllSubscription = React.lazy(() =>
	componentLoader(() => import('component/screen/manageUsers/ManageAllSubscription'), 2)
);

//By default isSuperAdmin will have access to everything
export const rbacRules = (auth: any, isEnrolled: any) => {
	const { user, adminEnable, teacherEnable } = auth;
	const isAuthenticated = auth && user;

	let dyad_role = '',
		user_role = ROLE_TYPE['USER'],
		admin_role = '',
		teacher_role = '',
		enrolled_role = '';

	if (user?.roles?.includes('dyad substitute')) {
		dyad_role = ROLE_TYPE['USER'];
	}

	if (adminEnable) {
		admin_role = ROLE_TYPE['ADMIN_ROLE'];
	}

	if (teacherEnable) {
		teacher_role = ROLE_TYPE['TEACHER_ROLE'];
	}

	if (isEnrolled) {
		enrolled_role = ROLE_TYPE['ENROLLED_ROLE'];
	}

	return [
		{
			route: `/login`,
			isRouteAuthenticated: false,
			component: Login,
			rolesPermitted: [user_role],
			public_route: true,
		},
		{
			route: `/register`,
			isRouteAuthenticated: false,
			component: Registration,
			rolesPermitted: [user_role],
			public_route: true,
		},

		{
			route: `/audioVideoTest`,
			isRouteAuthenticated: isAuthenticated,
			component: AudioVideoTest,
			rolesPermitted: [user_role],
		},

		{
			route: `/admin-dashboard`,
			isRouteAuthenticated: isAuthenticated,
			component: AdminDashboard,
			rolesPermitted: [admin_role, teacher_role],
		},

		{
			route: `/payment`,
			isRouteAuthenticated: isAuthenticated,
			component: PaymentSuccess,
			rolesPermitted: [user_role],
		},

		{
			route: `/users&auth=1`,
			isRouteAuthenticated: isAuthenticated,
			component: ManageUsers,
			rolesPermitted: [admin_role],
		},

		{
			route: `/users`,
			isRouteAuthenticated: isAuthenticated,
			component: ManageUsers,
			rolesPermitted: [admin_role],
		},

		{
			route: `/all/subscription`,
			isRouteAuthenticated: isAuthenticated,
			component: ManageAllSubscription,
			rolesPermitted: [admin_role],
		},
		{
			route: `/course-subscription`,
			isRouteAuthenticated: isAuthenticated,
			component: Subscription,
			rolesPermitted: [admin_role],
		},

		{
			route: `/user-registration`,
			isRouteAuthenticated: isAuthenticated,
			component: RegisterUser,
			rolesPermitted: [admin_role],
		},

		{
			route: `/import-users`,
			isRouteAuthenticated: isAuthenticated,
			component: ImportUsers,
			rolesPermitted: [admin_role],
		},

		{
			route: `/welcome`,
			isRouteAuthenticated: isAuthenticated,
			component: WelcomeScreen,
			rolesPermitted: [admin_role, teacher_role],
		},

		{
			route: `/create-pairs`,
			isRouteAuthenticated: isAuthenticated,
			component: CourseCohortPair,
			rolesPermitted: [admin_role, teacher_role],
		},

		{
			route: `/validate-pairs`,
			isRouteAuthenticated: isAuthenticated,
			component: ValidateCoursePairing,
			rolesPermitted: [admin_role],
		},

		{
			route: `/subscription-list`,
			isRouteAuthenticated: isAuthenticated,
			component: SubscriptionPairingList,
			rolesPermitted: [admin_role, teacher_role],
		},
		{
			route: `/community-enrollment`,
			isRouteAuthenticated: isAuthenticated,
			component: CommunityEnrollment,
			rolesPermitted: [admin_role, teacher_role],
		},

		{
			route: `/email`,
			isRouteAuthenticated: isAuthenticated,
			component: Messaging,
			rolesPermitted: [admin_role],
		},

		{
			route: `/send-email`,
			isRouteAuthenticated: isAuthenticated,
			component: DashboardAlert,
			rolesPermitted: [admin_role, teacher_role],
		},

		{
			route: `/all-pages`,
			isRouteAuthenticated: isAuthenticated,
			component: CMS,
			rolesPermitted: [admin_role],
		},

		{
			route: `/add-page`,
			isRouteAuthenticated: isAuthenticated,
			component: AddCMS,
			rolesPermitted: [admin_role],
		},

		{
			route: `/checkout/:courseId`,
			isRouteAuthenticated: isAuthenticated,
			component: Payment,
			rolesPermitted: [user_role],
		},

		/** --------------New Dashboard Start -------------------------------------- */
		{
			route: `/`,
			isRouteAuthenticated: isAuthenticated,
			component: NewDashboard,
			rolesPermitted: [user_role],
			public_route: false,
		},

		{
			route: `/dashboard/diagnostics`,
			isRouteAuthenticated: isAuthenticated,
			component: NewDashboard,
			rolesPermitted: [user_role],
		},

		{
			route: `/dashboard/diagnostics/:roomId`,
			isRouteAuthenticated: isAuthenticated,
			component: NewDashboard,
			rolesPermitted: [user_role],
		},

		{
			route: `/dashboard`,
			isRouteAuthenticated: isAuthenticated,
			component: NewDashboard,
			rolesPermitted: [user_role],
		},

		{
			route: `/dashboard/dyads/:dyadsId`,
			isRouteAuthenticated: isAuthenticated,
			component: NewDashboard,
			rolesPermitted: [user_role],
		},

		{
			route: `/dashboard&bypass=1`,
			isRouteAuthenticated: isAuthenticated,
			component: NewDashboard,
			rolesPermitted: [user_role],
		},

		/** --------------New Dashboard End -------------------------------------- */

		/** --------------Zoom Dashboard Start -------------------------------------- */
		{
			route: `/zoom`,
			isRouteAuthenticated: isAuthenticated,
			component: ZoomDashboard,
			rolesPermitted: [user_role],
			public_route: false,
		},

		{
			route: `/zoom/dashboard/diagnostics`,
			isRouteAuthenticated: isAuthenticated,
			component: ZoomDashboard,
			rolesPermitted: [user_role],
		},

		{
			route: `/zoom/dashboard/diagnostics/:roomId`,
			isRouteAuthenticated: isAuthenticated,
			component: ZoomDashboard,
			rolesPermitted: [user_role],
		},

		{
			route: `/zoom/dashboard`,
			isRouteAuthenticated: isAuthenticated,
			component: ZoomDashboard,
			rolesPermitted: [user_role],
		},

		{
			route: `/zoom/dashboard/dyads/:dyadsId`,
			isRouteAuthenticated: isAuthenticated,
			component: ZoomDashboard,
			rolesPermitted: [user_role],
		},

		{
			route: `/zoom/dashboard&bypass=1`,
			isRouteAuthenticated: isAuthenticated,
			component: ZoomDashboard,
			rolesPermitted: [user_role],
		},

		/** --------------Zoom Dashboard End -------------------------------------- */

		{
			route: `/dyadHistory`,
			isRouteAuthenticated: isAuthenticated,
			component: DyadHistory,
			rolesPermitted: [admin_role],
		},

		{
			route: `/dyad-schedule`,
			isRouteAuthenticated: isAuthenticated,
			component: DyadSchedule,
			rolesPermitted: [user_role],
		},

		{
			route: `/inbox`,
			isRouteAuthenticated: isAuthenticated,
			component: UserInbox,
			rolesPermitted: [enrolled_role],
		},

		{
			route: `/admin-inbox`,
			isRouteAuthenticated: isAuthenticated,
			component: AdminInbox,
			rolesPermitted: [admin_role],
		},

		{
			route: `/teacher-inbox`,
			isRouteAuthenticated: isAuthenticated,
			component: TeacherInbox,
			rolesPermitted: [teacher_role],
		},

		{
			route: `/feedback`,
			isRouteAuthenticated: isAuthenticated,
			component: Feedback,
			rolesPermitted: [user_role],
		},

		{
			route: `/audioCameraTest`,
			isRouteAuthenticated: isAuthenticated,
			component: AudioVideoTest,
			rolesPermitted: [user_role],
		},

		{
			route: `/profile`,
			isRouteAuthenticated: isAuthenticated,
			component: Profile,
			rolesPermitted: [user_role],
		},

		{
			route: `/subscribe`,
			isRouteAuthenticated: isAuthenticated,
			component: SubscriptionPopup,
			rolesPermitted: [user_role],
		},

		{
			route: `/subscription`,
			isRouteAuthenticated: isAuthenticated,
			component: MySubscription,
			rolesPermitted: [user_role],
		},
		{
			route: `/weekly-enrolled`,
			isRouteAuthenticated: isAuthenticated,
			component: WeeklyEnrolled,
			rolesPermitted: [enrolled_role],
		},
		{
			route: `/course`,
			isRouteAuthenticated: isAuthenticated,
			component: StudentCourse,
			rolesPermitted: [user_role],
		},

		{
			route: `/my-course`,
			isRouteAuthenticated: isAuthenticated,
			component: MyCourse,
			rolesPermitted: [user_role, admin_role, teacher_role],
			// rolesPermitted: [!onlyDyadDubstitute],
		},

		{
			route: `/my-course/preview/:cid`,
			isRouteAuthenticated: isAuthenticated,
			component: MyCourse,
			rolesPermitted: [user_role],
			// rolesPermitted: [!onlyDyadDubstitute],
		},

		{
			route: `/community`,
			isRouteAuthenticated: isAuthenticated,
			rolesPermitted: [user_role],
			component: Community,
		},

		{
			route: `/contact-us`,
			isRouteAuthenticated: isAuthenticated,
			component: ContactUs,
			rolesPermitted: [user_role],
		},

		{
			route: `/forgot-password`,
			isRouteAuthenticated: false,
			component: ForgotPassword,
			rolesPermitted: [user_role],
			public_route: true,
		},

		{
			route: `/reset-password`,
			isRouteAuthenticated: isAuthenticated,
			component: ResetPassword,
			rolesPermitted: [user_role],
			public_route: true,
		},

		{
			route: `/verify-email`,
			isRouteAuthenticated: isAuthenticated,
			component: VerifyEmail,
			rolesPermitted: [user_role],
			public_route: true,
		},

		{
			route: `/admin/course-content`,
			isRouteAuthenticated: isAuthenticated,
			component: CourseContent,
			rolesPermitted: [admin_role],
		},
	];
};
