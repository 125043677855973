import { useState } from 'react';
import axios from 'axios';
import i18n from 'i18n/i18n';
import uk from '../../../assets/uk.png';
import germany from '../../../assets/germany.png';

const languageList: any = [
	{ value: 'en', label: 'English' },
	{ value: 'de', label: 'German' },
];

const userLang: any = navigator.languages;
function systemLanguage(): any {
	for (let i = 0; i < languageList.length; i++) {
		for (let j = 0; j < userLang.length; j++) {
			if (userLang.includes(languageList[i]['value'])) {
				return i;
			}
		}
	}
	return 0;
}

const langIndex = systemLanguage();

const Multilingual = () => {
	const [langSelectOpen, setLangSelectOpen] = useState<boolean>(false);
	const [lang, setLang] = useState<any>(languageList[langIndex]);

	const handleBlur = ({ currentTarget, relatedTarget }: any) => {
		if (currentTarget.contains(relatedTarget)) return;
		setLangSelectOpen(false);
	};

	const changeLang = (val: any) => {
		setLang(val);
		axios.defaults.headers.common['Accept-Language'] = val.value;
		i18n.changeLanguage(val.value);
		setLangSelectOpen(false);
	};

	return (
		<div className="d-flex flex-row mx-3">
			<div className="p-2">
				<img width="30px" className="mobile-lang-flag" src={lang.label === 'English' ? uk : germany} alt="flag icon" />
			</div>
			<div>
				<form autoComplete="off">
					<div tabIndex={0} className="lang-dropdownMobile me-3" onBlur={handleBlur}>
						<div onClick={() => setLangSelectOpen(!langSelectOpen)} className="lang-dropbtnMbl">
							{lang?.label}
						</div>
						{langSelectOpen && (
							<div className="lang-dropdown-content">
								{languageList.map((item: any, index: any) => {
									return (
										<div
											className={lang.value === item.value ? 'lang-option-label lang-selected' : 'lang-option-label'}
											onClick={() => changeLang(item)}>
											{item?.label}
										</div>
									);
								})}
							</div>
						)}
					</div>
				</form>
			</div>
		</div>
	);
};

export default Multilingual;
