import React from 'react';
import './NotFound.css';
import { useTranslation } from 'react-i18next';

const AccessDenied: React.FC = () => {
	const { t } = useTranslation('notFound');
	return (
		<div className="access-denied-wrapper">
			<h1>
				<code className="access-denied-head">{t('AccessDenied')}</code>
			</h1>
			<hr />
			<h3 className="access-denied-msg">{t('notPermissionMsg')}</h3>
		</div>
	);
};

export default AccessDenied;
