export const DEL_COHORT_REQ = 'DEL_COHORT_REQ';
export const DEL_COHORT_SUCCESS = 'DEL_COHORT_SUCCESS';
export const DEL_COHORT_FAIL = 'DEL_COHORT_FAIL';

export const DEL_COURSE_REQ = 'DEL_COURSE_REQ';
export const DEL_COURSE_SUCCESS = 'DEL_COURSE_SUCCESS';
export const DEL_COURSE_FAIL = 'DEL_COURSE_FAIL';

export const UPDATE_COHORT_REQ = 'UPDATE_COHORT_REQ';
export const UPDATE_COHORT_SUCCESS = 'UPDATE_COHORT_SUCCESS';
export const UPDATE_COHORT_FAIL = 'UPDATE_COHORT_FAIL';

export const UPDATE_COURSE_REQ = 'UPDATE_COURSE_REQ';
export const UPDATE_COURSE_SUCCESS = 'UPDATE_COURSE_SUCCESS';
export const UPDATE_COURSE_FAIL = 'UPDATE_COURSE_FAIL';

export const DEL_COCOON_REQ = 'DEL_COCOON_REQ';
export const DEL_COCOON_SUCCESS = 'DEL_COCOON_SUCCESS';
export const DEL_COCOON_FAIL = 'DEL_COCOON_FAIL';

export const UPDATE_COCOON_REQ = 'UPDATE_COCOON_REQ';
export const UPDATE_COCOON_SUCCESS = 'UPDATE_COCOON_SUCCESS';
export const UPDATE_COCOON_FAIL = 'UPDATE_COCOON_FAIL';

export const GET_USERS_REQ = 'GET_USERS_REQ';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';

export const RESET_PASSWORD_REQ = 'RESET_PASSWORD_REQ';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const PAIR_USERS_REQ = 'PAIR_USERS_REQ';
export const PAIR_USERS_SUCCESS = 'PAIR_USERS_SUCCESS';
export const PAIR_USERS_FAIL = 'PAIR_USERS_FAIL';

export const PAIRED_USERS_LIST_REQ = 'PAIRED_USERS_LIST_REQ';
export const PAIRED_USERS_LIST_SUCCESS = 'PAIRED_USERS_LIST_SUCCESS';
export const PAIRED_USERS_LIST_FAIL = 'PAIRED_USERS_LIST_FAIL';

export const PAIRED_USERS_SEARCH_LIST_REQ = 'PAIRED_USERS_SEARCH_LIST_REQ';
export const PAIRED_USERS_SEARCH_LIST_SUCCESS = 'PAIRED_USERS_SEARCH_LIST_SUCCESS';
export const PAIRED_USERS_SEARCH_LIST_FAIL = 'PAIRED_USERS_SEARCH_LIST_FAIL';

export const DELETE_PAIRED_USERS_REQ = 'DELETE_PAIRED_USERS_REQ';
export const DELETE_PAIRED_USERS_SUCCESS = 'DELETE_PAIRED_USERS_SUCCESS';
export const DELETE_PAIRED_USERS_FAIL = 'DELETE_PAIRED_USERS_FAIL';

export const UPDATE_PAIRED_USERS_REQ = 'UPDATE_PAIRED_USERS_REQ';
export const UPDATE_PAIRED_USERS_SUCCESS = 'UPDATE_PAIRED_USERS_SUCCESS';
export const UPDATE_PAIRED_USERS_FAIL = 'UPDATE_PAIRED_USERS_FAIL';

export const MESSAGE_STATUS = 'MESSAGE_STATUS';

export const ALL_SUBSRIBED_USERS_INIT = 'ALL_SUBSRIBED_USERS_INIT';
export const ALL_SUBSRIBED_USERS_SUCCESS = 'ALL_SUBSRIBED_USERS_SUCCESS';
export const ALL_SUBSRIBED_USERS_FAIL = 'ALL_SUBSRIBED_USERS_FAIL';
