import axios from 'axios';
const BASE_API_URL = process.env.REACT_APP_API_URL;

export const GetAPI = async (endPoint: string) => {
	try {
		const url = `${BASE_API_URL}` + `${endPoint}`;
		const response = await axios.get(url);
		const { data } = response;
		return data;
	} catch (error) {
		return {
			message: 'error',
			data: error,
		};
	}
};

export const GetAPIForId = async (endPoint: string, id: any) => {
	try {
		const url = `${BASE_API_URL}` + `${endPoint}/${id}`;
		const response = await axios.get(url);
		const { data } = response;
		return data;
	} catch (error) {
		return {
			message: 'error',
			data: error,
		};
	}
};

export const PostAPI = async (endPoint: string, body: any, id: string, config: any) => {
	try {
		const url = `${BASE_API_URL}` + `${endPoint}` + `${id}`;
		const response = await axios.post(url, body, config);
		return response;
	} catch (error) {
		return {
			message: 'error',
			data: error,
		};
	}
};

export const UpdateAPI = async (endPoint: string, body: any, id: any, config: any) => {
	try {
		const tempData = Object.fromEntries(Object.entries(body));
		const updatedData = JSON.stringify(tempData);
		const url = `${BASE_API_URL}${endPoint}${id}`;
		const response = await axios.put(url, updatedData, config);
		return response;
	} catch (error) {
		return {
			message: 'error',
			data: error,
		};
	}
};

export const UpdatePostAPI = async (endPoint: string, body: any, id: any) => {
	try {
		const tempData = Object.fromEntries(Object.entries(body).filter(([_, v]) => v != ''));
		const updatedData = JSON.stringify(tempData);
		const url = `${BASE_API_URL} + ${endPoint}`;
		const response = await axios.post(url, updatedData, {
			params: {
				id: `${id}`,
			},
		});
		const { data } = response;
		return data;
	} catch (error) {
		return {
			message: 'error',
			data: error,
		};
	}
};

export const DeleteAPI = async (endPoint: any, id: any) => {
	try {
		const url = `${BASE_API_URL}` + `${endPoint}` + `/${id}`;
		const response = axios.delete(url);
		return response;
	} catch (error) {
		return {
			message: 'error',
			data: error,
		};
	}
};

export const UpdatePatchAPI = async (endPoint: string, body: any, id: string, config: any) => {
	try {
		const tempData = Object.fromEntries(Object.entries(body).filter(([_, v]) => v != ''));
		const updatedData = JSON.stringify(tempData);
		const url = `${BASE_API_URL}` + `${endPoint}` + `${id}`;
		const response = await axios.patch(url, updatedData, config);
		return response;
	} catch (error) {
		return {
			message: 'error',
			data: error,
		};
	}
};

export const getCourseUser = async (dyadsId: any) => {
	try {
		const apiUrl = 'api/users/participant/dashboard';
		const list = await GetAPI(apiUrl);

		if (list && list.participantsList) {
			const filteredUsers = list.participantsList.filter((user: any) => {
				if (dyadsId) {
					return user['cocoonName'] === dyadsId && !user['isCommunity'];
				}
				return !user['isCommunity'];
			});

			if (filteredUsers.length > 0) {
				return filteredUsers[0];
			}
		}

		return null; // Return null if no user found
	} catch (error) {
		return {
			message: 'error',
			data: error,
		};
	}
};

export const getCommunityUser = async (dyadsId: any) => {
	try {
		const apiUrl = 'api/users/community-participant/dashboard';
		const list = await GetAPI(apiUrl);

		if (list && list.participantsList) {
			const filteredUsers = list.participantsList.filter((user: any) => {
				if (dyadsId) {
					return user['cocoonName'] === dyadsId;
				}
				return true;
			});

			if (filteredUsers.length > 0) {
				return filteredUsers[0]; // Return the first matching user
			}
		}

		return null; // Return null if no user found
	} catch (error) {
		return {
			message: 'error',
			data: error,
		};
	}
};
