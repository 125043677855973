import {
	SUBSCRIPTION_INIT,
	SUBSCRIPTION_SUCCESS,
	SUBSCRIPTION_FAIL,
	DYAD_SCHEDULE_INIT,
	DYAD_SCHEDULE_SUCCESS,
	DYAD_SCHEDULE_FAIL,
	PARTICIPANTS_COURSE_LIST_INIT,
	PARTICIPANTS_COURSE_LIST_SUCCESS,
	PARTICIPANTS_COURSE_LIST_FAIL,
	PARTICIPANTS_COMMUNITY_LIST_INIT,
	PARTICIPANTS_COMMUNITY_LIST_SUCCESS,
	PARTICIPANTS_COMMUNITY_LIST_FAIL,
	TT_SESSION_INIT,
	TT_SESSION_SUCCESS,
	TT_SESSION_FAIL,
	DYAD_PARTICIPANTS_INIT,
	DYAD_PARTICIPANTS_SUCCESS,
	DYAD_PARTICIPANTS_CLEAR,
	BELL_ICON_INIT,
	BELL_ICON_SUCCESS,
	BELL_ICON_FAIL,
} from '../constants/dashboard';
const initialState = {
	loading_subscription: false,
	loading_dyad_schedule: false,
	loading_FDP_participants: false,
	loading_community_participants: false,
	loading_tt_session: false,
	loading_dyad_partner: false,
	loading_bell: false,
	active_subscription: null,
	dyad_schedule: [],
	course_participants: null,
	community_participants: null,
	tt_sessions: null,
	dyad_user_participants: null,
	is_bell_icon: null,
};

const dashboardReducer = (state = initialState, action: any) => {
	const { type, payload } = action;
	switch (type) {
		case SUBSCRIPTION_INIT:
			return {
				...state,
				loading_subscription: true,
			};
		case SUBSCRIPTION_SUCCESS:
			return {
				...state,
				loading_subscription: false,
				active_subscription: payload,
			};
		case SUBSCRIPTION_FAIL:
			return {
				...state,
				loading_subscription: false,
				active_subscription: payload,
			};

		case DYAD_SCHEDULE_INIT:
			return {
				...state,
				loading_dyad_schedule: true,
			};
		case DYAD_SCHEDULE_SUCCESS:
			return {
				...state,
				loading_dyad_schedule: false,
				dyad_schedule: payload,
			};
		case DYAD_SCHEDULE_FAIL:
			return {
				...state,
				loading_dyad_schedule: false,
				dyad_schedule: payload,
			};

		case PARTICIPANTS_COURSE_LIST_INIT:
			return {
				...state,
				loading_FDP_participants: true,
			};
		case PARTICIPANTS_COURSE_LIST_SUCCESS:
			return {
				...state,
				loading_FDP_participants: false,
				course_participants: payload,
			};
		case PARTICIPANTS_COURSE_LIST_FAIL:
			return {
				...state,
				loading_FDP_participants: false,
				course_participants: payload,
			};

		case PARTICIPANTS_COMMUNITY_LIST_INIT:
			return {
				...state,
				loading_community_participants: true,
			};
		case PARTICIPANTS_COMMUNITY_LIST_SUCCESS:
			return {
				...state,
				loading_community_participants: false,
				community_participants: payload,
			};
		case PARTICIPANTS_COMMUNITY_LIST_FAIL:
			return {
				...state,
				loading_community_participants: false,
				community_participants: payload,
			};

		case TT_SESSION_INIT:
			return {
				...state,
				loading_tt_session: true,
			};
		case TT_SESSION_SUCCESS:
			return {
				...state,
				loading_tt_session: false,
				tt_sessions: payload,
			};
		case TT_SESSION_FAIL:
			return {
				...state,
				loading_tt_session: false,
				tt_sessions: payload,
			};

		case DYAD_PARTICIPANTS_INIT:
			return {
				...state,
				loading_dyad_partner: true,
			};
		case DYAD_PARTICIPANTS_SUCCESS:
			return {
				...state,
				loading_dyad_partner: false,
				dyad_user_participants: payload,
			};
		case DYAD_PARTICIPANTS_CLEAR:
			return {
				...state,
				loading_dyad_partner: false,
				dyad_user_participants: null,
			};

		case BELL_ICON_INIT:
			return {
				...state,
				loading_bell: true,
			};
		case BELL_ICON_SUCCESS:
			return {
				...state,
				loading_bell: false,
				is_bell_icon: payload,
			};
		case BELL_ICON_FAIL:
			return {
				...state,
				loading_bell: false,
				is_bell_icon: null,
			};

		default:
			return state;
	}
};

export default dashboardReducer;
