import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

const PublicRoutes = (props: any) => {
	const token = localStorage.getItem('token');
	const navigate = useNavigate();

	if (token && props.public_route) {
		navigate('/');
	}

	if (!token && !props.public_route) {
		window.location.href = '/login';
	}

	return (
		<Fragment>
			<div className="container-fluid page-body-wrapper ">{props.elementComponant}</div>
		</Fragment>
	);
};

export default PublicRoutes;
