import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import axios from 'axios';
import { getResposeType } from './utils/apiResponseHandler';
import { toast } from 'react-toastify';
import './i18n/i18n';
import './assets/fonts/National-Regular.otf';
import './assets/fonts/National-Semibold.otf';
import { BsCheckCircleFill } from 'react-icons/bs';
import TagManager from 'react-gtm-module';
const tagManagerArgs = {
	gtmId: process.env.REACT_APP_GTMID + '',
};
console.log('tagManagerArgs........', tagManagerArgs);
console.log('NODE_ENV........', process.env.NODE_ENV);
console.log('REACT_APP_BASE_URL........', process.env.REACT_APP_BASE_URL);
if (
	process.env.REACT_APP_BASE_URL === 'https://my.humanize.com/' ||
	process.env.REACT_APP_BASE_URL === 'https://www.humanizeappdev.com/'
)
	TagManager.initialize(tagManagerArgs);

axios.interceptors.request.use((config) => {
	if (!window.navigator.onLine) {
		toast.error('No Intenet Connection');
		throw new axios.Cancel('Network Error..!');
	}
	return config;
});

let refresh = false;
axios.interceptors.response.use(
	(resp) => {
		if (resp?.request?.__rollbar_xhr?.method !== 'GET') {
			const toastResponse = getResposeType(resp);
			if (toastResponse?.message !== null)
				toast.success(toastResponse?.message, {
					icon: <BsCheckCircleFill color="#2db3b6" size={20} />,
				});
		}
		return resp;
	},
	async (error) => {
		if (error && error.response && error.response.status === 401 && !refresh) {
			axios.defaults.headers.common['x-auth-token'] = `Bearer ${localStorage.getItem('refreshToken')}`;
			axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('refreshToken')}`;
			refresh = true;
			const response = await axios.post(process.env.REACT_APP_API_URL + 'api/refreshToken', {
				refreshToken: localStorage.getItem('refreshToken'),
			});

			if (response && (response.status === 200 || response.status === 201)) {
				localStorage.setItem('token', response.data['accessToken']);
				error.config._retry = true;
				error.config.headers['x-auth-token'] = `Bearer ${response.data['accessToken']}`;
				error.config.headers['Authorization'] = `Bearer ${response.data['accessToken']}`;

				delete axios.defaults.headers.common['x-auth-token'];
				delete axios.defaults.headers.common['Authorization'];
				axios.defaults.headers.common['x-auth-token'] = `Bearer ${response.data['accessToken']}`;
				axios.defaults.headers.common['Authorization'] = `Bearer ${response.data['accessToken']}`;
				return axios(error.config);
			}
		} else if (error && error.response && error.response.status !== 401) {
			const toastResponse = getResposeType(error.response);
			if (toastResponse?.message !== null && !Array.isArray(toastResponse?.message)) {
				toast.error(toastResponse?.message);
			} else if (toastResponse?.message !== null && Array.isArray(toastResponse?.message)) {
				let messages: any = toastResponse?.message;
				for (let msg of messages) {
					toast.error(msg);
				}
			}
		}
		refresh = false;
		return error;
	}
);
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<Provider store={store}>
		<App />
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
