import {
	DEL_COHORT_REQ,
	DEL_COHORT_SUCCESS,
	DEL_COHORT_FAIL,
	UPDATE_COHORT_REQ,
	UPDATE_COHORT_SUCCESS,
	UPDATE_COHORT_FAIL,
	DEL_COCOON_REQ,
	DEL_COCOON_SUCCESS,
	DEL_COCOON_FAIL,
	UPDATE_COCOON_REQ,
	UPDATE_COCOON_SUCCESS,
	UPDATE_COCOON_FAIL,
	GET_USERS_REQ,
	GET_USERS_SUCCESS,
	GET_ALL_USERS_SUCCESS,
	GET_USERS_FAIL,
	PAIR_USERS_REQ,
	PAIR_USERS_SUCCESS,
	PAIR_USERS_FAIL,
	PAIRED_USERS_LIST_REQ,
	PAIRED_USERS_LIST_SUCCESS,
	PAIRED_USERS_LIST_FAIL,
	PAIRED_USERS_SEARCH_LIST_REQ,
	PAIRED_USERS_SEARCH_LIST_SUCCESS,
	PAIRED_USERS_SEARCH_LIST_FAIL,
	DELETE_PAIRED_USERS_FAIL,
	DELETE_PAIRED_USERS_SUCCESS,
	DELETE_PAIRED_USERS_REQ,
	UPDATE_PAIRED_USERS_REQ,
	UPDATE_PAIRED_USERS_SUCCESS,
	UPDATE_PAIRED_USERS_FAIL,
	DEL_COURSE_REQ,
	DEL_COURSE_SUCCESS,
	DEL_COURSE_FAIL,
	UPDATE_COURSE_REQ,
	UPDATE_COURSE_SUCCESS,
	UPDATE_COURSE_FAIL,
	MESSAGE_STATUS,
	ALL_SUBSRIBED_USERS_INIT,
	ALL_SUBSRIBED_USERS_SUCCESS,
	ALL_SUBSRIBED_USERS_FAIL,
} from '../constants/admin';
const initialState = {
	loading: false,
	loadingSubsribedUser: false,
	allUsers: [],
	pairedUsers: [],
	allUsersData: [],
	subscribedUser: [],
	isRead: false,
};

const adminReducer = (state = initialState, action: any) => {
	const { type, payload } = action;
	switch (type) {
		case DEL_COHORT_REQ:
			return {
				...state,
				loading: true,
			};
		case DEL_COURSE_REQ:
			return {
				...state,
				loading: true,
			};
		case DEL_COHORT_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case DEL_COURSE_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case DEL_COHORT_FAIL:
			return {
				...state,
				loading: false,
			};
		case DEL_COURSE_FAIL:
			return {
				...state,
				loading: false,
			};
		case UPDATE_COHORT_REQ:
			return {
				...state,
				loading: true,
			};
		case UPDATE_COHORT_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case UPDATE_COHORT_FAIL:
			return {
				...state,
				loading: false,
			};
		case UPDATE_COURSE_REQ:
			return {
				...state,
				loading: true,
			};
		case UPDATE_COURSE_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case UPDATE_COURSE_FAIL:
			return {
				...state,
				loading: false,
			};
		case DEL_COCOON_REQ:
			return {
				...state,
				loading: true,
			};
		case DEL_COCOON_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case DEL_COCOON_FAIL:
			return {
				...state,
				loading: false,
			};
		case UPDATE_COCOON_REQ:
			return {
				...state,
				loading: true,
			};
		case UPDATE_COCOON_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case UPDATE_COCOON_FAIL:
			return {
				...state,
				loading: false,
			};
		case GET_USERS_REQ:
			return {
				...state,
				loading: true,
			};
		case GET_USERS_SUCCESS:
			return {
				...state,
				loading: false,
				allUsers: payload,
			};
		case GET_ALL_USERS_SUCCESS:
			return {
				...state,
				loading: false,
				allUsersData: payload,
			};
		case MESSAGE_STATUS:
			return {
				...state,
				loading: false,
				isRead: payload,
			};
		case GET_USERS_FAIL:
			return {
				...state,
				loading: false,
			};
		case PAIR_USERS_REQ:
			return {
				...state,
				loading: true,
			};
		case PAIR_USERS_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case PAIR_USERS_FAIL:
			return {
				...state,
				loading: false,
			};
		case PAIRED_USERS_LIST_REQ:
		case PAIRED_USERS_SEARCH_LIST_REQ:
			return {
				...state,
				loading: true,
			};
		case PAIRED_USERS_LIST_SUCCESS:
		case PAIRED_USERS_SEARCH_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				pairedUsers: payload,
			};
		case PAIRED_USERS_LIST_FAIL:
		case PAIRED_USERS_SEARCH_LIST_FAIL:
			return {
				...state,
				loading: false,
			};
		case DELETE_PAIRED_USERS_REQ:
		case UPDATE_PAIRED_USERS_REQ:
			return {
				...state,
				loading: true,
			};
		case DELETE_PAIRED_USERS_SUCCESS:
		case UPDATE_PAIRED_USERS_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case DELETE_PAIRED_USERS_FAIL:
		case UPDATE_PAIRED_USERS_FAIL:
			return {
				...state,
				loading: false,
			};

		case ALL_SUBSRIBED_USERS_INIT:
			return {
				...state,
				loading_subscription: true,
			};
		case ALL_SUBSRIBED_USERS_SUCCESS:
			return {
				...state,
				loading_subscription: false,
				subscribedUser: payload,
			};
		case ALL_SUBSRIBED_USERS_FAIL:
			return {
				...state,
				loading_subscription: false,
				subscribedUser: payload,
			};
		default:
			return state;
	}
};

export default adminReducer;
