import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import AUTH_EN from '../locale/en/auth.json';
import AUTH_DE from '../locale/de/auth.json';
import HEADER_EN from '../locale/en/header.json';
import HEADER_DE from '../locale/de/header.json';
import DASHBBOARD_EN from '../locale/en/dashboard.json';
import DASHBBOARD_DE from '../locale/de/dashboard.json';
import CLASSES_EN from '../locale/en/classes.json';
import CLASSES_DE from '../locale/de/classes.json';
import DYADS_EN from '../locale/en/dyads.json';
import DYADS_DE from '../locale/de/dyads.json';
import FOOTER_EN from '../locale/en/footer.json';
import FOOTER_DE from '../locale/de/footer.json';
import GENERATION_EN from '../locale/en/generation.json';
import GENERATION_DE from '../locale/de/generation.json';
import NOTFOUND_EN from '../locale/en/notFound.json';
import NOTFOUND_DE from '../locale/de/notFound.json';
import PROFILE_EN from '../locale/en/profile.json';
import PROFILE_DE from '../locale/de/profile.json';
import ROOM_EN from '../locale/en/room.json';
import ROOM_DE from '../locale/de/room.json';
import SCHEDULE_EN from '../locale/en/schedule.json';
import SCHEDULE_DE from '../locale/de/schedule.json';
import AUDIOVIDEOTEST_EN from '../locale/en/audioVideoTest.json';
import AUDIOVIDEOTEST_DE from '../locale/de/audioVideoTest.json';
import SIDEBAR_EN from '../locale/en/sidebar.json';
import SIDEBAR_DE from '../locale/de/sidebar.json';
import ADMIN_DASHBOARD_EN from '../locale/en/adminDashboard.json';
import ADMIN_DASHBOARD_DE from '../locale/de/adminDashboard.json';
import RESET_USER_PASSWORD_EN from '../locale/en/resetUserPassword.json';
import RESET_USER_PASSWORD_DE from '../locale/de/resetUserPassword.json';
import COMMON_EN from '../locale/en/common.json';
import COMMON_DE from '../locale/de/common.json';
import PAIRUSERS_EN from '../locale/en/pairUsers.json';
import PAIRUSERS_DE from '../locale/de/pairUsers.json';
import DYAD_HISTORY_EN from '../locale/en/dyadHistory.json';
import DYAD_HISTORY_DE from '../locale/de/dyadHistory.json';
import DYADSCHEDULE_EN from '../locale/en/dyadSchedule.json';
import DYADSCHEDULE_DE from '../locale/de/dyadSchedule.json';
import TEACHERGENRATION_EN from '../locale/en/teacherGeneration.json';
import TEACHERGENRATION_DE from '../locale/de/teacherGeneration.json';
import ZOOMEMBED_EN from '../locale/en/zoomEmbed.json';
import ZOOMEMBED_DE from '../locale/de/zoomEmbed.json';
import STUDENT_COURSE_EN from '../locale/en/studentCourse.json';
import STUDENT_COURSE_DE from '../locale/de/studentCourse.json';
import DYAD_COMMUNITY_EN from '../locale/en/community.json';
import DYAD_COMMUNITY_DE from '../locale/de/community.json';

// the translations
const resources = {
	en: {
		auth: AUTH_EN,
		header: HEADER_EN,
		dashboard: DASHBBOARD_EN,
		classes: CLASSES_EN,
		dyads: DYADS_EN,
		footer: FOOTER_EN,
		generation: GENERATION_EN,
		notFound: NOTFOUND_EN,
		profile: PROFILE_EN,
		audioVideoTest: AUDIOVIDEOTEST_EN,
		room: ROOM_EN,
		schedule: SCHEDULE_EN,
		sidebar: SIDEBAR_EN,
		adminDashboard: ADMIN_DASHBOARD_EN,
		resetUserPassword: RESET_USER_PASSWORD_EN,
		common: COMMON_EN,
		pairUsers: PAIRUSERS_EN,
		dyadHistory: DYAD_HISTORY_EN,
		dyadSchedule: DYADSCHEDULE_EN,
		teacherGeneration: TEACHERGENRATION_EN,
		zoomEmbed: ZOOMEMBED_EN,
		studentCourse: STUDENT_COURSE_EN,
		community: DYAD_COMMUNITY_EN,
	},
	de: {
		auth: AUTH_DE,
		header: HEADER_DE,
		dashboard: DASHBBOARD_DE,
		classes: CLASSES_DE,
		dyads: DYADS_DE,
		footer: FOOTER_DE,
		generation: GENERATION_DE,
		notFound: NOTFOUND_DE,
		profile: PROFILE_DE,
		audioVideoTest: AUDIOVIDEOTEST_DE,
		room: ROOM_DE,
		schedule: SCHEDULE_DE,
		sidebar: SIDEBAR_DE,
		adminDashboard: ADMIN_DASHBOARD_DE,
		resetUserPassword: RESET_USER_PASSWORD_DE,
		common: COMMON_DE,
		pairUsers: PAIRUSERS_DE,
		dyadHistory: DYAD_HISTORY_DE,
		dyadSchedule: DYADSCHEDULE_DE,
		teacherGeneration: TEACHERGENRATION_DE,
		zoomEmbed: ZOOMEMBED_DE,
		studentCourse: STUDENT_COURSE_DE,
		community: DYAD_COMMUNITY_DE,
	},
};

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		fallbackLng: 'en', // use en if detected lng is not available

		// keySeparator: false, // we do not use keys in form messages.welcome

		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

export default i18n;
