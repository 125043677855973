export const ZOOM_CREATE_CLIENT_INIT = 'ZOOM_CREATE_CLIENT_INIT';
export const ZOOM_CREATE_CLIENT_SUCCESS = 'ZOOM_CREATE_CLIENT_SUCCESS';
export const ZOOM_CREATE_CLIENT_FAIL = 'ZOOM_CREATE_CLIENT_FAIL';
// export const SET_ZOOM_PAGE = 'SET_ZOOM_PAGE ';
export const ZOOM_JOIN_INIT = 'ZOOM_JOIN_INIT';
export const ZOOM_JOIN_SUCCESS = 'ZOOM_JOIN_SUCCESS';
export const ZOOM_JOIN_RESET = 'ZOOM_JOIN_RESET';
export const ZOOM_COCOON_NAME = 'ZOOM_COCOON_NAME';
export const ZOOM_UPDATE_PARTNER_INIT = 'ZOOM_UPDATE_PARTNER_INIT';
export const ZOOM_UPDATE_PARTNER_SUCCESS = 'ZOOM_UPDATE_PARTNER_SUCCESS';
export const ZOOM_UPDATE_PARTNER_FAIL = 'ZOOM_UPDATE_PARTNER_FAIL';

export const ZOOM_VIDEO_INIT = 'ZOOM_VIDEO_INIT';
export const ZOOM_VIDEO_SUCCESS = 'ZOOM_VIDEO_SUCCESS';
export const ZOOM_VIDEO_FAIL = 'ZOOM_VIDEO_FAIL';

export const ZOOM_AUDIO_INIT = 'ZOOM_AUDIO_INIT';
export const ZOOM_AUDIO_SUCCESS = 'ZOOM_AUDIO_SUCCESS';
export const ZOOM_AUDIO_FAIL = 'ZOOM_AUDIO_FAIL';

export const ZOOM_AUDIO_MUTE_INIT = 'ZOOM_AUDIO_MUTE_INIT';
export const ZOOM_AUDIO_MUTE_SUCCESS = 'ZOOM_AUDIO_MUTE_SUCCESS';
export const ZOOM_AUDIO_MUTE_FAIL = 'ZOOM_AUDIO_MUTE_FAIL';

export const ZOOM_PARTNER_ENABLE_VIDEO = 'ZOOM_PARTNER_ENABLE_VIDEO';
export const ZOOM_PARTNER_CONNECTIONED = 'ZOOM_PARTNER_CONNECTIONED';
export const ZOOM_PARTNER_REMOVED = 'ZOOM_PARTNER_REMOVED';
export const ZOOM_SPEAKER_ACTIVE_ID = 'ZOOM_SPEAKER_ACTIVE_ID';
export const ZOOM_MEDIA_STREAM = 'ZOOM_MEDIA_STREAM';

export const ZOOM_CAMERA_ON_OFF = 'ZOOM_CAMERA_ON_OFF';
export const SCRIPT_AUTO_START = 'SCRIPT_AUTO_START';
export const SET_SPEAKER_LISTNER_PARTNER = 'SET_SPEAKER_LISTNER_PARTNER';
export const SET_LISTNER_PARTNER = 'SET_LISTNER_PARTNER';
export const SET_SPEAKER_PARTNER = 'SET_SPEAKER_PARTNER';
export const SET_USER_PERMISSION = 'SET_USER_PERMISSION ';
export const SET_CURRENT_USER_SPEAKER_LISTNER = 'SET_CURRENT_USER_SPEAKER_LISTNER ';
export const SET_ACTIVE_COCOONID = 'SET_ACTIVE_COCOONID ';
export const ZOOM_STOP_DYAD = 'ZOOM_STOP_DYAD ';

export const CANVAS_WIDTH = 1920;
export const CANVAS_HEIGHT = 1080;
export const ASPECT_RATIO = 1;
export const ZOOM_LANG = 'en-US';
export const ZOOM_PATH = `Global`;
export const NUMBER_OF_PARTNERS_DYAD = 2;
