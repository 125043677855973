export const REGISTER_REQ = 'REGISTER_REQ';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const LOGIN_REQ = 'LOGIN_REQ';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const USER_LOAD_REQ = 'USER_LOAD_REQ';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';

export const UPDATE_USER_REQ = 'UPDATE_USER_REQ';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const UPLOAD_PIC_REQ = 'UPLOAD_PIC_REQ';
export const UPLOAD_PIC_SUCCESS = 'UPLOAD_PIC_SUCCESS';
export const UPLOAD_PIC_FAIL = 'UPLOAD_PIC_FAIL';

export const FORGOT_PASSWORD_REQ = 'FORGOT_PASSWORD_REQ';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';

export const RESET_PASSWORD_REQ = 'RESET_PASSWORD_REQ';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const VERIFY_EMAIL_REQ = 'VERIFY_EMAIL_REQ';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAIL = 'VERIFY_EMAIL_FAIL';

export const ADMIN_DASHBOARD_ENABLE = 'ADMIN_DASHBOARD_ENABLE';
export const ADMIN_DASHBOARD_DISABLE = 'ADMIN_DASHBOARD_DISABLE';
export const TEACHER_DASHBOARD_ENABLE = 'TEACHER_DASHBOARD_ENABLE';
export const TEACHER_DASHBOARD_DISABLE = 'TEACHER_DASHBOARD_DISABLE';

export const STUDENT_DASHBOARD_ENABLE = 'STUDENT_DASHBOARD_ENABLE';
export const STUDENT_DASHBOARD_DISABLE = 'STUDENT_DASHBOARD_DISABLE';

export const LANGUAGE_CHANGE = 'LANGUAGE_CHANGE';

export const CONNECT_TO_CHAT = 'CONNECT_TO_CHAT';
export const DISCONNECT_TO_CHAT = 'DISCONNECT_TO_CHAT';

export const SUBSCRIBED_USER_EXISTS = 'SUBSCRIBED_USER_EXISTS';
export const SUBSCRIBED_USER_NOT_EXISTS = 'SUBSCRIBED_USER_EXISTS';

export const INIT_CONNECT_TO_CHAT = 'INIT_CONNECT_TO_CHAT';

export const IP_ADDRESS_INIT = 'IP_ADDRESS_INIT';
export const IP_ADDRESS_SUCCESS = 'IP_ADDRESS_SUCCESS';
export const IP_ADDRESS_FAIL = 'IP_ADDRESS_FAIL';
