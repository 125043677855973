import spacetime from 'spacetime';
import soft from 'timezone-soft';
import allTimezones from '../profile/components/timeZoneSelect/timezone-list';
import moment from 'moment-timezone';

interface ITimezoneOption {
	value: string;
	label: string;
	abbrev?: any;
	altName?: string;
	offset?: number;
}

type ITimezone = ITimezoneOption | string;

// This method give the entire list of timezones
export const getAllTimezoneList = () => {
	return generateTimezoneOptions(allTimezones, 'original', false);
};

// Function to generate an array of timezone options
export const generateTimezoneOptions = (timezones: any, labelStyle = 'original', showLabel: boolean) => {
	// Use Object.entries to iterate over each timezone entry
	return (
		Object.entries(timezones)
			.reduce<ITimezoneOption[]>((selectOptions, zone) => {
				// Create a spacetime instance for the current timezone
				const now = spacetime.now(zone[0]);
				// Get timezone information
				const tz = now.timezone();
				// Get timezone strings using the 'soft' method
				const tzStrings = soft(zone[0]);

				// Initialize variables for label, abbreviation, and alternative name
				let label = '';
				let abbr = moment().tz(zone[0]).isDST() ? tzStrings[0].daylight?.abbr : tzStrings[0].standard?.abbr;
				let altName = moment().tz(zone[0]).isDST() ? tzStrings[0].daylight?.name : tzStrings[0].standard?.name;

				// Calculate minutes and hours offset from GMT
				const min = tz.current.offset * 60;
				const hr = `${(min / 60) ^ 0}:` + (min % 60 === 0 ? '00' : Math.abs(min % 60));
				//Here, we're checking whether we should show label along with offest, e.g: (GMT+5:30) IST-Kolkata or Just GMT+5:30
				const prefix = showLabel
					? `(GMT${hr.includes('-') ? hr : `+${hr}`}) ${zone[1]}`
					: `GMT${hr.includes('-') ? hr : `+${hr}`}`;
				// Determine the label based on the specified style
				switch (labelStyle) {
					case 'original':
						label = prefix;
						break;
					case 'altName':
						label = `${prefix} ${altName?.length ? `(${altName})` : ''}`;
						break;
					case 'abbrev':
						label = `${prefix} ${abbr?.length < 5 ? `(${abbr})` : ''}`;
						break;
					default:
						label = `${prefix}`;
				}
				// Push the timezone option to the selectOptions array
				selectOptions.push({
					value: tz.name,
					label: label,
					offset: tz.current.offset,
					abbrev: abbr,
					altName: altName,
				});

				// Return the updated selectOptions array
				return selectOptions;
			}, [])
			// Sort the array of timezone options based on the offset
			.sort((a: ITimezoneOption, b: ITimezoneOption) => (a?.offset || 0) - (b?.offset || 0))
	);
};

// Function to get the timezone abbreviation based on the selected zone
export const getTimeZoneAbbreviation = (optionList: any, zone: any) => {
	// Find the timezone option from the provided optionList that matches the selected zone
	const timeZone = optionList.find((item: any) => item?.value === zone);

	if (timeZone !== undefined) {
		return timeZone;
	}

	// If the timezone is not found, attempt to get the user's browser timezone using moment.tz.guess()
	let userBrowserTimeZone = moment.tz.guess(true);

	if (userBrowserTimeZone === 'Asia/Calcutta') userBrowserTimeZone = 'Asia/Kolkata';

	// Find the timezone option from the provided optionList that matches the user's browser timezone
	return optionList.find((item: any) => item?.value === userBrowserTimeZone);
};

export const toLocalTimezone = (dateStr: any, defaultTimeZone: any) => {
	// Construct a local timezone date string ignoring the source timezone
	const dateStrNoTz = moment(dateStr).tz(defaultTimeZone).format('YYYY-MM-DDTHH:mm:ss');
	return moment(dateStrNoTz).format();
};

// Function to convert a date to a different timezone
export const timezoneConverter = (date: any, defaultTimeZone: any) => {
	// Extract the offset from the provided defaultTimeZone label
	let offset = defaultTimeZone.label;

	// Remove 'GMT' from the offset
	offset = offset.replace('GMT', '');

	// Split the offset into hours and minutes
	offset = offset.split(':');

	// Check and standardize the hours format (e.g., convert '03' to '3')
	if (offset[0].length === 2) {
		offset = offset[0].charAt(0) + '0' + offset[0].charAt(1) + ':' + offset[1];
	} else {
		offset = offset[0] + ':' + offset[1];
	}

	// Append the adjusted offset to the input date (excluding the last 6 characters, assumed to be the original offset)
	return date.substr(0, date.length - 6) + offset;
};

/**
 *
 * @param defaultTimeZone and week
 * @param week
 * @returns weekly cron schdule as per the timezone
 */
export const cronSchedule = (defaultTimeZone: any, week: any) => {
	//Default time e.g, Friday, 10 pm
	let cron_week = 'Friday',
		cron_hr = 22,
		cron_sec = 0,
		init_date,
		default_formate = 'h:mm a [every] dddd';

	if (week?.day) {
		cron_week = week['day'];
	}
	if (week?.cron_hr) {
		cron_hr = week['cron_hr'];
	}
	if (week?.cron_sec) {
		cron_sec = week['cron_sec'];
	}

	if (week?.init_date) {
		init_date = week['init_date'];
		default_formate = 'h:mm a [on] dddd, MMM D';
	}
	const friday10PMUTC = moment.utc(init_date).day(cron_week).hour(cron_hr).minute(cron_sec);

	const weekly_time = friday10PMUTC.clone().tz(defaultTimeZone?.value)?.format(default_formate);
	return weekly_time;
};

//replace string in a sentence with mulitple given sentence
export const replaceMultipleStrings = (sentence: string, replacements: any) => {
	if (!sentence) {
		return '';
	}

	//seperated object key by pipe to identify
	var pattern = new RegExp(Object.keys(replacements).join('|'), 'g');

	// update the second sentence to replace data
	var result = sentence.replace(pattern, function (match: any) {
		return replacements[match];
	});

	return result;
};

// Function to parse and return a time zone option based on the input.
export const parseTimezoneOption = (zone: ITimezone, getOptions: any) => {
	if (typeof zone === 'string') {
		// Try to find an exact match in the options; if not found, perform a fuzzy search.
		return (
			getOptions.find((tz: any) => tz.value === zone) ||
			(zone.indexOf('/') !== -1 && findBestMatchingTimezone(zone, getOptions))
		);
	} else if (zone?.value && !zone?.label) {
		// Attempt to find a matching time zone option based on the value property.
		return getOptions.find((tz: any) => tz.value === zone?.value);
	}
	// Return null if no matching time zone option is found.
	return null;
};

// Function to find the best-matching time zone based on certain criteria and a fuzzy search.
const findBestMatchingTimezone = (zone: string, getOptions: any): ITimezoneOption | undefined => {
	// Get the current time in GMT as the default time zone.
	let currentTime = spacetime.now('GMT');
	try {
		// Attempt to get the current time in the specified time zone.
		currentTime = spacetime.now(zone);
	} catch (err) {
		// Return undefined if there's an error (e.g., invalid time zone).
		return;
	}

	// Filter time zone options based on their offsets compared to the current time zone.
	return (
		getOptions
			.filter((tz: ITimezoneOption) => tz.offset === currentTime.timezone().current.offset)
			.map((tz: ITimezoneOption) => {
				let score = 0;
				// Check conditions related to the time zone, such as offset, DST, and name structure.
				if (
					currentTime.timezones[tz.value.toLowerCase()] &&
					!!currentTime.timezones[tz.value.toLowerCase()].dst === currentTime.timezone().hasDst
				) {
					if (tz.value.toLowerCase().indexOf(currentTime.tz.substring(currentTime.tz.indexOf('/') + 1)) !== -1) {
						score += 8;
					}
					if (tz.label.toLowerCase().indexOf(currentTime.tz.substring(currentTime.tz.indexOf('/') + 1)) !== -1) {
						score += 4;
					}
					if (tz.value.toLowerCase().indexOf(currentTime.tz.substring(0, currentTime.tz.indexOf('/')))) {
						score += 2;
					}
					score += 1;
				} else if (tz.value === 'GMT') {
					score += 1;
				}
				return { tz, score };
			})
			// Sort options by their scores in descending order.
			.sort((a: any, b: any) => b.score - a.score)
			// Return the time zone with the highest score.
			.map((tz: ITimezoneOption) => tz)[0]
	);
};
