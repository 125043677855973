import {
  // JOIN_ROOM_INIT,
  JOIN_ROOM_SUCCESS,
  JOIN_ROOM_FAIL,
  REMOVE_DYAD_REQ,
  REMOVE_DYAD_SUCCESS,
  REMOVE_DYAD_FAIL,
  // LEAVE_ROOM_INIT,
  // LEAVE_ROOM_SUCCESS,
  // LEAVE_ROOM_FAIL,
} from "../constants/room";

const initialState = {
  loading: true,
  joinRoom: null,
  error: null,
};
const roomReducer = (state = initialState, action: any) => {
  const { type, payload, error } = action;
  switch (type) {
    case JOIN_ROOM_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
        joinRoom: payload,
      };
    case JOIN_ROOM_FAIL:
      return {
        ...state,
        ...payload,
        loading: false,
        error: error,
      };
    case REMOVE_DYAD_REQ:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_DYAD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case REMOVE_DYAD_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default roomReducer;
