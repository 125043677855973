import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { loadedUser } from 'redux/actions/auth';
import PrivateRoute from './PrivateRoute';
import PublicRoutes from './PublicRoutes';
import { useSelector } from 'react-redux';
import Loader from 'component/common/loader/Loader';
import { RootState } from 'redux/reducers';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import axios from 'axios';
import { rbacRules, Header, Footer, NotFound } from './rbac_rules';

function AppRoutes() {
	const dispatch: Dispatch<any> = useDispatch();
	useEffect(() => {
		dispatch(loadedUser());
	}, []);

	// const [isEnrolled, setIsEnrolled] = useState<boolean>(false);
	const auth = useSelector((state: RootState) => state.auth);
	// const userInfo = useSelector((state: RootState) => state.auth.user);
	const isEnrolled = useSelector((state: RootState) => state.subscription);
	// useEffect(() => {
	// 	getSubscriptionData();
	// }, [userInfo]);

	// const getSubscriptionData = async () => {
	// 	try {
	// 		axios.get(process.env.REACT_APP_API_URL + `api/community`).then((res) => {
	// 			if (res.data && res.data.data) {
	// 				console.log(`Inside ,,,`);
	// 				setIsEnrolled(true);
	// 			}
	// 		});
	// 	} catch (error) {}
	// };

	const routes = rbacRules(auth, isEnrolled);
	return (
		<React.Suspense fallback={<Loader />}>
			<Header isEnrolled={isEnrolled} />
			<Routes>
				{routes.map(({ route, component: Component, isRouteAuthenticated, rolesPermitted, public_route }, index) => (
					<Route
						key={index}
						path={route}
						element={
							isRouteAuthenticated ? (
								<PrivateRoute route={route} rolesPermitted={rolesPermitted} elementComponant={<Component />}>
									<Component />
								</PrivateRoute>
							) : (
								<PublicRoutes public_route={public_route} route={route} elementComponant={<Component />}>
									<Component />
								</PublicRoutes>
							)
						}
					/>
				))}

				<Route path="*" element={<NotFound />} />
			</Routes>
			<Footer />
		</React.Suspense>
	);
}

export default AppRoutes;
