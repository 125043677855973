export const SUBSCRIPTION_INIT = 'SUBSCRIPTION_INIT';
export const SUBSCRIPTION_SUCCESS = 'SUBSCRIPTION_SUCCESS';
export const SUBSCRIPTION_FAIL = 'SUBSCRIPTION_FAIL';

export const DYAD_SCHEDULE_INIT = 'DYAD_SCHEDULE_INIT';
export const DYAD_SCHEDULE_SUCCESS = 'DYAD_SCHEDULE_SUCCESS';
export const DYAD_SCHEDULE_FAIL = 'DYAD_SCHEDULE_FAIL';

export const PARTICIPANTS_COURSE_LIST_INIT = 'PARTICIPANTS_COURSE_LIST_INIT';
export const PARTICIPANTS_COURSE_LIST_SUCCESS = 'PARTICIPANTS_COURSE_LIST_SUCCESS';
export const PARTICIPANTS_COURSE_LIST_FAIL = 'PARTICIPANTS_COURSE_LIST_FAIL';

export const PARTICIPANTS_COMMUNITY_LIST_INIT = 'PARTICIPANTS_COMMUNITY_LIST_INIT';
export const PARTICIPANTS_COMMUNITY_LIST_SUCCESS = 'PARTICIPANTS_COMMUNITY_LIST_SUCCESS';
export const PARTICIPANTS_COMMUNITY_LIST_FAIL = 'PARTICIPANTS_COMMUNITY_LIST_FAIL';

export const DYAD_PARTICIPANTS_INIT = 'DYAD_PARTICIPANTS_INIT';
export const DYAD_PARTICIPANTS_SUCCESS = 'DYAD_PARTICIPANTS_SUCCESS';
export const DYAD_PARTICIPANTS_CLEAR = 'DYAD_PARTICIPANTS_CLEAR';

export const TT_SESSION_INIT = 'TT_SESSION_INIT';
export const TT_SESSION_SUCCESS = 'TT_SESSION_SUCCESS';
export const TT_SESSION_FAIL = 'TT_SESSION_FAIL';

export const BELL_ICON_INIT = 'BELL_ICON_INIT';
export const BELL_ICON_SUCCESS = 'BELL_ICON_SUCCESS';
export const BELL_ICON_FAIL = 'BELL_ICON_FAIL';
